import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { constants } from '@/shared/constants';
import { CheckablePeriodUnitType, SearchDateType } from '@/shared/constants/code';
import dayjs from 'dayjs';
import {
  CustomerConditionType,
  CustomerGetRequestType,
  CustomerGetResponseType,
} from '@/shared/types/api/api.customer';

export const customer = [
  rest.post<CustomerGetRequestType>(
    `${config.baseURL}/group/:groupId/customer/search`,
    (req, res, ctx) => {
      // const folderId = req.url.searchParams.get('folderId');
      // const searchWord: string | null = req.url.searchParams.get('customerName');
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const sortTarget: string = String(req.url.searchParams.get('sortTarget'));
      const sortOrder: string = String(req.url.searchParams.get('sortOrder'));
      const condition: CustomerConditionType & { customerNames?: string[] } = req.body;
      const negativeWord: string =
        '粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任';

      const result: CustomerGetResponseType[] = Array.from({ length: 205 }).map((i, index) => {
        return {
          customerId: index + 1,
          customerName: `${index + 1} :: スタディプログラミングステッパーズ - ${faker.word.noun()}`,
          managementNumber: faker.word.noun(),
          relativeWord: faker.word.noun(),
          executionConditionId: index + 1,
          executionConditionTitle: faker.word.noun(),
          negativeWord,
          checkablePeriodNumber: index % 4 === 2 ? undefined : index % 15,
          checkablePeriodUnit:
            index % 4 === 0
              ? (constants.code.checkable_period_unit.day as CheckablePeriodUnitType)
              : index % 4 === 1
              ? (constants.code.checkable_period_unit.week as CheckablePeriodUnitType)
              : index % 4 === 2
              ? (constants.code.checkable_period_unit.year as CheckablePeriodUnitType)
              : undefined,
          registeredAt: String(faker.date.recent(index)),
          checkedAt: String(faker.date.recent(index)),
          isSiteFilter: false,
          searchDateType: 'ALL' as SearchDateType,
          folderId: 1,
          folderName: 'フォルダ名',
        };
      });

      const filteredResult =
        condition.customerNames?.length ?? -1 > 0
          ? result.filter(
              ({ customerName }) =>
                condition.customerNames?.filter((c) => customerName.includes(c))?.length ?? -1 > 0,
            )
          : result;

      const dateStartData = condition.checkedAt?.start
        ? filteredResult.filter(({ checkedAt }) => {
            return dayjs(checkedAt).isAfter(condition.checkedAt?.start);
          })
        : filteredResult;

      const dateEndData = condition.checkedAt?.end
        ? dateStartData.filter(({ checkedAt }) => {
            return dayjs(condition.checkedAt?.end).isAfter(checkedAt);
          })
        : dateStartData;

      const sortResult =
        sortTarget && sortOrder
          ? dateEndData.sort((x, y) => {
              if (sortTarget === 'registeredAt') {
                return sortOrder === 'desc'
                  ? dayjs(x.registeredAt).diff(dayjs(y.registeredAt))
                  : dayjs(y.registeredAt).diff(dayjs(x.registeredAt));
              }

              if (sortTarget === 'checkedAt') {
                return sortOrder === 'desc'
                  ? dayjs(x.checkedAt).diff(dayjs(y.checkedAt))
                  : dayjs(y.checkedAt).diff(dayjs(x.checkedAt));
              }
              return 0;
            })
          : dateEndData;

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: sortResult.slice(offset, offset + limit),
        }),
      );
    },
  ),
];
