import { selector, DefaultValue } from 'recoil';

import { blacklistRestrictionDialogState } from '../atoms/blacklistRestrictionDialogState';

import { BlacklistRestrictionDialogType } from '@/shared/types/common.type';

export const blacklistRestrictionDialogSelector = selector<BlacklistRestrictionDialogType>({
  key: 'blacklistRestrictionDialogSelector',
  get: ({ get }) => get(blacklistRestrictionDialogState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue.open) {
      reset(blacklistRestrictionDialogState);
    }
    set(blacklistRestrictionDialogState, newValue);
  },
});
