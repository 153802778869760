import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { TableResultType } from '@/shared/types/api.type';
import { constants } from '@/shared/constants';
import {
  CheckConditionGetResponseType,
  CheckConditionPostResponseType,
} from '@/shared/types/api/api.checkCondition';
export const checkConditionSetting = [
  rest.get<TableResultType<CheckConditionGetResponseType[]>>(
    `${config.baseURL}/group/:groupId/check-condition`,
    (req, res, ctx) => {
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const searchWord: string | null = req.url.searchParams.get('title');
      const negativeWord: string =
        '粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任';

      const result = Array.from({ length: 102 }).map((i, index) => {
        const searchDateType =
          index % 3 === 0
            ? constants.code.search_date_type_unit.all
            : index % 3 === 1
            ? constants.code.search_date_type_unit.unit
            : index % 3 === 2
            ? constants.code.search_date_type_unit.date
            : 0;
        return {
          checkConditionSettingId: index + 1,
          title: `${index + 1} :: RoboRoboおすすめ - ${faker.word.noun()} `,
          negativeWord,
          isSiteFilter: index % 4 === 2,
          isDefault: index === 0,
          searchDateType,
          searchStartDate:
            searchDateType === constants.code.search_date_type_unit.date
              ? faker.date.recent(index).toDateString()
              : undefined,
          searchEndDate:
            searchDateType === constants.code.search_date_type_unit.date
              ? faker.date.recent(index).toDateString()
              : undefined,
          checkablePeriodNumber:
            searchDateType === constants.code.search_date_type_unit.unit ? index % 15 : undefined,
          checkablePeriodUnit:
            searchDateType === constants.code.search_date_type_unit.unit
              ? index % 4 === 0
                ? constants.code.checkable_period_unit.day
                : index % 4 === 1
                ? constants.code.checkable_period_unit.week
                : index % 4 === 2
                ? constants.code.checkable_period_unit.year
                : undefined
              : undefined,
        };
      });

      const filteredResult = searchWord
        ? result.filter(({ title }) => title.includes(searchWord))
        : result;

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: filteredResult.length,
          offset,
          limit,
          rows: limit === 0 ? filteredResult : filteredResult.slice(offset, offset + limit),
        }),
      );
    },
  ),
  rest.post<CheckConditionPostResponseType>(
    `${config.baseURL}/group/:group_id//check-condition`,
    (req, res, ctx) => {
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          checkConditionSettingIds: 1,
        }),
      );
    },
  ),
];
