import styled from '@emotion/styled';

import styles from '@/shared/styles';

export const ButtonContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 40px;
  right: 16px;
  width: 40px;
  height: 40px;
  background: ${styles.color.white};
  border: ${styles.border.normal};
  border-radius: 8px;
`;
