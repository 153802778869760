import { ComponentType, lazy } from 'react';

import useLazyImportReload from '@/components/lazyImport/useLazyImportReload';

const lazyImport = (factory: () => Promise<{ default: ComponentType<any> }>) =>
  lazy(async () => {
    try {
      return await factory();
    } catch (e) {
      return {
        default: useLazyImportReload,
      };
    }
  });

export default lazyImport;
