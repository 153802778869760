import { checkServiceType } from '@/shared/utils/serviceType';

export const complianceToReferenceCheckLabel = (
  value: string,
  type: 'customer' | 'checkDecision' = 'customer',
) => {
  const isCompliance = checkServiceType();
  switch (type) {
    case 'checkDecision':
      return isCompliance ? value : value.replace('取引判断', '判断');
    case 'customer':
    default:
      return isCompliance
        ? value
        : ['取引先名', '取引先', '企業名'].reduce(
            (pre, current) => pre.split(current).join('個人名'),
            value,
          );
  }
};
