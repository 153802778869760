import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { usageCountGetResponseType } from '@/shared/types/api/api.usageCount';

export const usageCount = [
  rest.get<usageCountGetResponseType>(`${config.baseURL}/usage-count`, async (req, res, ctx) => {
    return res(
      ctx.delay(delayTime),
      ctx.status(200),
      ctx.json({
        checkUsage: faker.datatype.number({ min: 0, max: 50 }),
        newspaperUsage: faker.datatype.number({ min: 0, max: 50 }),
        creditManagementUsage: faker.datatype.number({ min: 0, max: 50 }),
      }),
    );
  }),
];
