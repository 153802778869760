import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { TableResultType } from '@/shared/types/api.type';
import {
  CheckResultGetResponseType,
  CheckResultHistoryGetResponseType,
  CheckResultConditionType,
  CheckResultBulkUpdateClickAtPutRequestType,
  CheckResultBulkUpdateClickAtPutResponseType,
} from '@/shared/types/api/api.checkResult';
import { CheckDecisionPostRequestType } from '@/shared/types/api/api.checkDecision';
import dayjs from 'dayjs';
import {
  CheckResultDetailExecuteConditionGetRequestType,
  CheckResultDetailExecuteConditionGetResponseType,
  CheckResultDetailSearchResponseType,
} from '@/shared/types/api/api.checkResultDetail';
import { code, ExecuteStatusType } from '@/shared/constants/code';

type MocksCheckResultType = CheckResultGetResponseType & {
  checkedAt: string;
  registeredAt: string;
};

export const checkResults = [
  rest.post<CheckResultConditionType>(
    `${config.baseURL}/group/:groupId/check-result/search`,
    (req, res, ctx) => {
      const searchWord: string | null = req.url.searchParams.get('customerName');
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const sortTarget: string = String(req.url.searchParams.get('sortTarget'));
      const sortOrder: string = String(req.url.searchParams.get('sortOrder'));
      const condition: CheckResultConditionType = req.body;

      const negativeWord: string =
        '粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任';

      const result: MocksCheckResultType[] = Array.from({ length: 205 }).map((i, index) => {
        const checkExecutionResults =
          index % 5 !== 0
            ? {
                riskLevelHighCount: faker.datatype.number({ min: 0, max: 50 }),
                riskLevelMiddleCount: faker.datatype.number({ min: 5, max: 15 }),
                riskLevelLowCount: faker.datatype.number({ min: 10, max: 20 }),
                riskLevelNoneCount:
                  index % 5 !== 0 ? faker.datatype.number({ min: 0, max: 10 }) : 0,
                riskLevelExclusionCount:
                  index % 5 === 1 ? faker.datatype.number({ min: 0, max: 5 }) : 0,
              }
            : {
                riskLevelHighCount: 0,
                riskLevelMiddleCount: 0,
                riskLevelLowCount: 0,
                riskLevelNoneCount: 0,
                riskLevelExclusionCount: 0,
              };
        const totalUrlCount: number | null =
          Object.values(checkExecutionResults).reduce((a, b) => a + b, 0) +
          faker.datatype.number({ min: 0, max: 10 });

        return {
          checkExecutionId: index + 1,
          customerId: index + 1000,
          customerName: `${index + 1} :: スタディプログラミングステッパーズ - ${faker.word.noun()}`,
          clickedAt: index >= 10 ? faker.date.recent(index) : undefined,
          relativeWord: faker.word.noun(),
          checkConditionTitle: faker.word.noun(),
          negativeWord,
          newspaperArticleCount:
            index % 3 === 0
              ? faker.datatype.number({ min: 0, max: 100 })
              : index % 3 === 1
              ? 0
              : undefined,
          newspaperUrl: index % 3 === 0 ? faker.internet.url() : undefined,
          checkedExecutionAt: faker.date.recent(index),
          checkDecisionChoice:
            index % 4 === 0
              ? 'APPROVE'
              : index % 4 === 1
              ? 'DISAPPROVE'
              : index % 4 === 2
              ? 'PENDING'
              : undefined,
          hasComment: faker.datatype.boolean(),
          isSiteFilter: faker.datatype.boolean(),
          totalUrlCount: totalUrlCount,
          firstAnalysisIsFinished: faker.datatype.boolean(),
          ...checkExecutionResults,
          checkedAt: String(faker.date.recent(index)),
          registeredAt: String(faker.date.recent(index)),
          executeStatus:
            index % 4 === 0
              ? ('PENDING' as ExecuteStatusType)
              : index % 4 === 1
              ? ('IS_EXECUTING' as ExecuteStatusType)
              : index % 4 === 2
              ? ('IS_FINISHED' as ExecuteStatusType)
              : ('ERROR' as ExecuteStatusType),
          pendingCount: 0,
          approveCount: 1,
          disApproveCount: 2,
        };
      });

      const filteredResult = searchWord
        ? result.filter(({ customerName }) => customerName.includes(searchWord))
        : result;

      const dateStartData = condition.checkedAt?.start
        ? filteredResult.filter(({ checkedAt }) => {
            return dayjs(checkedAt).isAfter(condition.checkedAt?.start);
          })
        : filteredResult;

      const dateEndData = condition.checkedAt?.end
        ? dateStartData.filter(({ checkedAt }) => {
            return dayjs(condition.checkedAt?.end).isAfter(checkedAt);
          })
        : dateStartData;

      const sortResult =
        sortTarget && sortOrder
          ? dateEndData.sort((x, y) => {
              if (sortTarget === 'registeredAt') {
                return sortOrder === 'desc'
                  ? dayjs(x.registeredAt).diff(dayjs(y.registeredAt))
                  : dayjs(y.registeredAt).diff(dayjs(x.registeredAt));
              }

              if (sortTarget === 'checkedAt') {
                return sortOrder === 'desc'
                  ? dayjs(x.checkedAt).diff(dayjs(y.checkedAt))
                  : dayjs(y.checkedAt).diff(dayjs(x.checkedAt));
              }
              return 0;
            })
          : dateEndData;

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: sortResult.slice(offset, offset + limit),
        }),
      );
    },
  ),
];

export const checkResultsHistory = [
  rest.get<TableResultType<CheckResultHistoryGetResponseType[]>>(
    `${config.baseURL}/group/:groupId/customer/:customerId/check-result/history`,
    (req, res, ctx) => {
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const result: CheckResultHistoryGetResponseType[] = Array.from({ length: 205 }).map(
        (i, index) => {
          const checkExecutionResults =
            index % 5 !== 0
              ? {
                  riskLevelHighCount: faker.datatype.number({ min: 0, max: 50 }),
                  riskLevelMiddleCount: faker.datatype.number({ min: 5, max: 15 }),
                  riskLevelLowCount: faker.datatype.number({ min: 10, max: 20 }),
                  riskLevelNoneCount:
                    index % 5 !== 0 ? faker.datatype.number({ min: 0, max: 10 }) : 0,
                  riskLevelExclusionCount:
                    index % 5 === 1 ? faker.datatype.number({ min: 0, max: 5 }) : 0,
                }
              : {
                  riskLevelHighCount: 0,
                  riskLevelMiddleCount: 0,
                  riskLevelLowCount: 0,
                  riskLevelNoneCount: 0,
                  riskLevelExclusionCount: 0,
                };
          const totalUrlCount =
            Object.values(checkExecutionResults).reduce((a, b) => a + b, 0) +
            faker.datatype.number({ min: 0, max: 10 });

          return {
            checkExecutionId: index + 1,
            executionHistoryId: index + 1000,
            checkedExecutionAt: faker.date.recent(index),
            executeStatus:
              index % 4 === 0
                ? (code.execute_status_unit.pending as ExecuteStatusType)
                : index % 4 === 1
                ? (code.execute_status_unit.is_executing as ExecuteStatusType)
                : index % 4 === 2
                ? (code.execute_status_unit.is_finished as ExecuteStatusType)
                : (code.execute_status_unit.error as ExecuteStatusType),
            executorLastNameKanji: faker.word.noun(),
            executorFirstNameKanji: faker.word.noun(),
            executorLastNameKana: faker.word.noun(),
            executorFirstNameKana: faker.word.noun(),
            newspaperArticleCount:
              index % 3 === 0
                ? faker.datatype.number({ min: 0, max: 100 })
                : index % 3 === 1
                ? 0
                : undefined,
            newspaperUrl: index % 3 === 0 ? faker.internet.url() : undefined,
            checkDecisionChoice:
              index % 4 === 0
                ? 'APPROVE'
                : index % 4 === 1
                ? 'DISAPPROVE'
                : index % 4 === 2
                ? 'PENDING'
                : undefined,
            firstAnalysisIsFinished: faker.datatype.boolean(),
            totalUrlCount: totalUrlCount,
            ...checkExecutionResults,
          };
        },
      );

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: result.slice(offset, offset + limit),
        }),
      );
    },
  ),
];

export const getCheckResultDetail = [
  rest.get<CheckResultDetailExecuteConditionGetResponseType>(
    `${config.baseURL}/group/:groupId/check-result/:checkResultId`,
    (req, res, ctx) => {
      const { group_id, customer_id } = req.params;
      const negativeWord: string =
        '粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任';

      const result: CheckResultDetailExecuteConditionGetResponseType = {
        checkExecutionId: faker.datatype.number(),
        customerName: `${group_id}:${customer_id} :: スタディプログラミングステッパーズ - ${faker.word.noun()}`,
        relativeWord: faker.word.noun(),
        negativeWord: negativeWord,
        newspaperArticleCount: faker.datatype.number({ min: 0, max: 100 }),
      };

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const searchCheckResultDetailList = [
  rest.post<TableResultType<CheckResultDetailSearchResponseType[]>>(
    `${config.baseURL}/group/:groupId/check-result/:checkResultId/detail/search`,
    (req, res, ctx) => {
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const result: CheckResultDetailSearchResponseType[] = Array.from({ length: 205 }).map(
        (i, index) => {
          return {
            checkExecutionResultId: index + 1,
            title: faker.word.noun(), //faker.lorem.text(),
            url: faker.internet.url(),
            domainName: faker.word.noun(),
            snippet: faker.lorem.text(), //faker.word.noun(),
            llmRiskLevelScore:
              index % 4 === 0
                ? 'HIGH'
                : index % 4 === 1
                ? 'MIDDLE'
                : index % 4 === 2
                ? 'LOW'
                : 'NONE',
            llmReason: faker.lorem.words(30), //faker.word.noun(),
            riskLevelScore:
              index % 4 === 0
                ? 'HIGH'
                : index % 4 === 1
                ? 'MIDDLE'
                : index % 4 === 2
                ? 'LOW'
                : 'NONE',
            isConfirmed: faker.datatype.boolean(),
            hasFile: faker.datatype.boolean(),
          };
        },
      );

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: result.slice(offset, offset + limit),
          hasLlmOption: faker.datatype.boolean(),
        }),
      );
    },
  ),
];

export const updateCheckDecision = [
  rest.post<Omit<CheckDecisionPostRequestType, 'checkResultId'>>(
    `${config.baseURL}/group/:group_id/check-result/:check_result_id/check-decision`,
    (req, res, ctx) => {
      const result = {};
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const getCheckResultDetailCheckCondition = [
  rest.get<CheckResultDetailExecuteConditionGetRequestType>(
    `${config.baseURL}/group/:group_id/check-execution/:checkExecutionId`,
    (req, res, ctx) => {
      const { group_id, customer_id } = req.params;
      const negativeWord: string =
        '粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任 脱税 申告漏れ 違反 逮捕 送検 検挙 捜査 捜索 指名手配 判決 提訴 告訴 罪 詐欺 不正 偽装 裏 疑 架空 暗躍 闇 グレー 悪 暴力団 ヤクザ 総会屋 インサイダー 相場操縦 行政処分 行政指導 粉飾 横領 着服 背任';

      const result: CheckResultDetailExecuteConditionGetResponseType = {
        checkExecutionId: faker.datatype.number(),
        customerName: `${group_id}:${customer_id} :: スタディプログラミングステッパーズ - ${faker.word.noun()}`,
        relativeWord: faker.word.noun(),
        negativeWord: negativeWord,
        newspaperArticleCount: faker.datatype.number({ min: 0, max: 100 }),
      };

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const bulkUpdateClickAt = [
  rest.put<CheckResultBulkUpdateClickAtPutResponseType>(
    `${config.baseURL}/group/:group_id/check-execution/bulk-check`,
    (req, res, ctx) => {
      const body: Omit<CheckResultBulkUpdateClickAtPutRequestType, 'groupId'> = req.body;

      const result: CheckResultBulkUpdateClickAtPutResponseType = {
        checkExecutionIdList: body.checkExecutionIdList,
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
