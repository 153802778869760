import { axiosInstanceWithoutAuth } from '@/shared/api/axios';
import { AuthPostResponseType } from '@/shared/types/api/api.auth';

export const authorizeUser = async (
  code: string,
  state: string | null,
): Promise<AuthPostResponseType> => {
  const { data } = await axiosInstanceWithoutAuth.post<AuthPostResponseType>(`/authorize/code`, {
    code,
    state,
    // NOTE: ローカル検証用
    callbackUrl:
      import.meta.env.VITE_LOCAL_ENABLE === '1'
        ? 'http://127.0.0.1:3000/'
        : import.meta.env.VITE_CALLBACK_URL,
  });
  return data;
};

export const refreshAccessToken = async (refreshToken: string): Promise<AuthPostResponseType> => {
  const { data } = await axiosInstanceWithoutAuth.post<AuthPostResponseType>(`/authorize/refresh`, {
    refreshToken,
  });
  return data;
};
