import { axiosInstance } from '@/shared/api/axios';
import { GroupsGetResponseType, GroupsType } from '@/shared/types/api/api.groups';

export const getGroups = async (): Promise<GroupsType> => {
  const { data } = await axiosInstance.get<GroupsGetResponseType[]>('/groups');
  return {
    groups: data.map((item) => ({
      key: item.groupName,
      value: item.groupId,
    })),
  };
};
