import { axiosInstance } from '@/shared/api/axios';
import { ErrorCode } from '@/shared/constants/error';
import { complianceToReferenceCheckLabel } from '@/shared/dictionary';
import { TableResultType } from '@/shared/types/api.type';
import {
  CheckConditionDeleteRequestType,
  CheckConditionDeleteResponseType,
  CheckConditionGetRequestType,
  CheckConditionGetResponseType,
  CheckConditionPostRequestType,
  CheckConditionPostResponseType,
  CheckConditionPutRequestType,
  CheckConditionPutResponseType,
  CheckConditionReflectionRequestType,
  CheckConditionReflectionResponseType,
} from '@/shared/types/api/api.checkCondition';

export const getCheckConditionSettings = async ({
  groupId,
  pageIndex,
  pageSize,
  searchWord,
}: CheckConditionGetRequestType): Promise<TableResultType<CheckConditionGetResponseType[]>> => {
  const { data } = await axiosInstance.get<TableResultType<CheckConditionGetResponseType[]>>(
    `/group/${groupId}/check-condition`,
    {
      params: {
        title: !searchWord ? undefined : searchWord,
        limit: pageSize === 0 ? undefined : pageSize,
        offset: pageSize === 0 ? undefined : (pageIndex - 1) * pageSize,
      },
    },
  );
  return data;
};

export const createCheckConditionSettings = async (
  groupId: number,
  checkablePeriodCondition: CheckConditionPostRequestType,
): Promise<CheckConditionPostResponseType> => {
  const { data } = await axiosInstance.post<CheckConditionPostResponseType>(
    `/group/${groupId}/check-condition`,
    {
      ...checkablePeriodCondition,
    },
  );

  return data;
};

export const updateCheckConditionSettings = async ({
  groupId,
  checkConditionSettingId,
  searchDateType,
  title,
  negativeWord,
  isSiteFilter,
  isDefault,
  checkablePeriodNumber,
  checkablePeriodUnit,
  searchStartDate,
  searchEndDate,
}: CheckConditionPutRequestType): Promise<CheckConditionPutResponseType> => {
  const { data } = await axiosInstance.put<CheckConditionPutResponseType>(
    `/group/${groupId}/check-condition/${checkConditionSettingId}`,
    {
      title,
      searchDateType,
      negativeWord,
      isSiteFilter,
      isDefault,
      checkablePeriodNumber,
      checkablePeriodUnit,
      searchStartDate,
      searchEndDate,
    },
  );

  return data;
};

export const deleteCheckConditionSettings = async ({
  groupId,
  checkConditionSettingIds,
}: CheckConditionDeleteRequestType): Promise<CheckConditionDeleteResponseType> => {
  const { data } = await axiosInstance.post<CheckConditionDeleteResponseType>(
    `/group/${groupId}/check-condition/delete`,
    {
      checkConditionSettingIds,
    },
  );

  return data;
};

export const reflectionCheckConditionSettings = async ({
  groupId,
  checkConditionSettingId,
  reflectionType,
  folderId,
  checkedPeriod,
}: CheckConditionReflectionRequestType): Promise<CheckConditionReflectionResponseType> => {
  const { data } = await axiosInstance.post<CheckConditionReflectionResponseType>(
    `/group/${groupId}/check-condition/${checkConditionSettingId}/reflection`,
    {
      reflectionType,
      folderId,
      checkedPeriod,
    },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_RESOURCE_NOT_FOUND,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: complianceToReferenceCheckLabel('チェック条件反映'),
            message: complianceToReferenceCheckLabel('取引先が見つかりませんでした。'),
          },
        },
      ],
    },
  );

  return data;
};
