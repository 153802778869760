import React from 'react';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { useErrorHandler } from 'react-error-boundary';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { tokenState } from '@/recoil/atoms/authInformationState';
import { tokenSelector } from '@/recoil/selectors/authInformationSelector';
import { messageDialogSelector } from '@/recoil/selectors/messageDialogSelector';
import { axiosErrorHandle } from '@/shared/api/error';
import { ErrorResponseType } from '@/shared/types/api.type';
import { AuthPostResponseType } from '@/shared/types/api/api.auth';
import { AuthType } from '@/shared/types/common.type';
import { redirectLoginForm } from '@/shared/utils/redirectLogin';

const timeout = Number(import.meta.env.VITE_API_TIMEOUT);

export const configWithoutAuth = {
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    withCredentials: true,
  },
  timeout: Number.isNaN(timeout) ? 30000 : timeout,
};

export const axiosInstanceWithoutAuth = axios.create(configWithoutAuth);

export const configWithFileUpload = {
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    withCredentials: true,
  },
  timeout: Number.isNaN(timeout) ? 30000 : timeout,
};

export const axiosInstanceWithFileUpload = axios.create(configWithFileUpload);

export const config = {
  baseURL: import.meta.env.VITE_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    withCredentials: true,
  },
  timeout: Number.isNaN(timeout) ? 30000 : timeout,
};

export const axiosInstance = axios.create(config);

type PropsType = {
  children: React.ReactElement;
};

export const AxiosClientProvider: React.FC<PropsType> = ({ children }) => {
  const tokenValue = useRecoilValue(tokenState);
  const setToken = useSetRecoilState(tokenSelector);
  const resetToken = useResetRecoilState(tokenSelector);
  const setDialogState = useSetRecoilState(messageDialogSelector);
  const handleError = useErrorHandler();

  React.useEffect(() => {
    const requestInterceptors = axiosInstance.interceptors.request.use(
      async (requestConfig: AxiosRequestConfig) => {
        let accessToken = tokenValue?.access_token;
        const refreshToken = tokenValue?.refresh_token;
        if (refreshToken && accessToken) {
          const decodedAccessToken: AuthType = jwtDecode(accessToken);
          const oneMinute = (new Date().getTime() + 60 * 1000) / 1000;
          const now = new Date().getTime() / 1000;
          if (decodedAccessToken.exp <= oneMinute || decodedAccessToken.exp <= now) {
            const { data } = await axiosInstanceWithoutAuth
              .post<AuthPostResponseType>(`/authorize/refresh`, {
                refreshToken,
              })
              .catch((e) => {
                resetToken();
                redirectLoginForm();
                throw e;
              });
            if (!decodedAccessToken.isValidRemoteIp) {
              window.location.href = import.meta.env.VITE_PORTAL_TOP_URL;
            }
            setToken(data);
            accessToken = data.access_token;
          }
          if (!requestConfig?.headers) {
            throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
          }

          // eslint-disable-next-line no-param-reassign
          requestConfig.headers.Authorization = `Bearer ${accessToken}`;
        } else {
          resetToken();
          redirectLoginForm();
        }

        return requestConfig;
      },
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError<ErrorResponseType>) => {
        const errorMessage = axiosErrorHandle(error);
        switch (errorMessage?.type) {
          case 'errorBoundary':
            handleError(error);
            break;
          case 'dialog':
            errorMessage.messageDialog && setDialogState(errorMessage.messageDialog);
            break;
          case 'authorizeRefresh': {
            const refreshToken = tokenValue?.refresh_token;
            if (!refreshToken) return Promise.reject(error);
            const { data } = await axiosInstanceWithoutAuth
              .post<AuthPostResponseType>(`/authorize/refresh`, {
                refreshToken,
              })
              .catch((e) => {
                resetToken();
                redirectLoginForm();
                throw e;
              });

            setToken(data);
            return axiosInstance.request({
              ...error.config,
              headers: {
                ...error.config.headers,
                Authorization: `Bearer ${data.access_token}`,
              },
            } as AxiosRequestConfig);
          }
          case 'reject':
          default:
            return Promise.reject(error);
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptors);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [
    handleError,
    resetToken,
    setDialogState,
    setToken,
    tokenValue?.access_token,
    tokenValue?.refresh_token,
  ]);

  return children;
};
