import { axiosInstance } from '@/shared/api/axios';
import { ErrorCode } from '@/shared/constants/error';
import { TableResultType } from '@/shared/types/api.type';
import {
  CreditManagementGetRequestType,
  CreditManagementGetResponseType,
  CreditManagementReExecutionPostRequestType,
  CreditManagementReExecutionPostResponseType,
  ExecutionCreditManagementDownloadPostRequestType,
  ExecutionCreditManagementDownloadPostResponseType,
} from '@/shared/types/api/api.creditManagement';

export const searchCreditManagements = async ({
  groupId,
  customerId,
  pageSize,
  pageIndex,
  searchWord,
}: CreditManagementGetRequestType): Promise<TableResultType<CreditManagementGetResponseType[]>> => {
  const { data } = await axiosInstance.get<TableResultType<CreditManagementGetResponseType[]>>(
    `/group/${groupId}/customer/${customerId}/credit-management-execute-result`,
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
        address: searchWord,
      },
    },
  );
  // TODO 暫定対応：gsearchId -> gSearchId
  return {
    ...data,
    rows: data.rows.map((d: any) => ({ ...d, gSearchId: d.gsearchId })),
  };
};

export const creditExecution = async ({
  customerId,
  gSearchId,
}: ExecutionCreditManagementDownloadPostRequestType): Promise<ExecutionCreditManagementDownloadPostResponseType> => {
  const { data } = await axiosInstance.post(
    `/execution/credit-management-downloads`,
    {
      customerId,
      gSearchId,
    },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_CREDIT_CHECK_IN_PROGRESS,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: '与信ダウンロード',
            message: '現在、与信情報ダウンロード中の取引先があるため実行できませんでした。',
          },
        },
      ],
    },
  );

  return data;
};

export const creditReExecution = async ({
  executionHistoryId,
}: CreditManagementReExecutionPostRequestType): Promise<CreditManagementReExecutionPostResponseType> => {
  const { data } = await axiosInstance.post(
    `/re-execution/credit-management-search`,
    { executionHistoryId },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_CREDIT_CHECK_IN_PROGRESS,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: '与信再調査',
            message: '現在、与信情報調査中の取引先があるため実行できませんでした。',
          },
        },
        {
          code: ErrorCode.ERROR_ALREADY_RE_EXECUTION,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: '与信再調査',
            message: '既に再調査済です。',
          },
        },
      ],
    },
  );

  return data;
};
