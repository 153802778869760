import { axiosInstance } from '@/shared/api/axios';
import {
  CustomerInformationGetRequest,
  CustomerInformationGetResponseType,
  CustomerInformationPostRequestType,
  CustomerInformationPostResponseType,
  CustomerInformationPutRequestType,
  CustomerInformationPutResponseType,
} from '@/shared/types/api/api.customerInformation';

export const getCustomerInformation = async ({
  groupId,
  customerId,
}: CustomerInformationGetRequest): Promise<CustomerInformationGetResponseType> => {
  const { data } = await axiosInstance.get<CustomerInformationGetResponseType>(
    `/group/${groupId}/customer/${customerId}/information`,
  );
  return data;
};

export const createCustomerInformation = async ({
  groupId,
  customerId,
  requestBody,
}: CustomerInformationPostRequestType): Promise<CustomerInformationPostResponseType> => {
  const { data } = await axiosInstance.post<CustomerInformationPostResponseType>(
    `/group/${groupId}/customer/${customerId}/information`,
    {
      ...requestBody,
    },
  );
  return data;
};

export const updateCustomerInformation = async ({
  groupId,
  customerId,
  customerInformationId,
  requestBody,
}: CustomerInformationPutRequestType): Promise<CustomerInformationPutResponseType> => {
  const { data } = await axiosInstance.put<CustomerInformationPutResponseType>(
    `/group/${groupId}/customer/${customerId}/information/${customerInformationId}`,
    {
      ...requestBody,
    },
  );
  return data;
};
