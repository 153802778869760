import React from 'react';

import { AxiosError } from 'axios';

import { MessageBodyStyle, MessageLabelStyle } from '@/components/MessageDialog/css';
import { ErrorCode, ErrorCodeType } from '@/shared/constants/error';
import { complianceToReferenceCheckLabel } from '@/shared/dictionary';
import styles from '@/shared/styles';
import { ErrorResponseType } from '@/shared/types/api.type';
import { MessageDialogType } from '@/shared/types/common.type';

export type ErrorMessageType = {
  code?: ErrorCodeType; // 'E02001';
  type: 'errorBoundary' | 'dialog' | 'reject' | 'authorizeRefresh';
  messageDialog?: MessageDialogType;
};

const errorMessages: ErrorMessageType[] = [
  {
    code: ErrorCode.ERROR_RESOURCE_DUPLICATE,
    type: 'dialog',
    messageDialog: {
      open: true,
      message: '既に登録されています',
    },
  },
  {
    code: ErrorCode.ERROR_DELETE_LINKED_CUSTOMERS,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: 'フォルダ削除',
      message: complianceToReferenceCheckLabel(
        'フォルダに紐づく取引先が存在するため削除できません。',
      ),
    },
  },
  {
    code: ErrorCode.ERROR_DUPLICATE_RELATIVE_WORD,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: complianceToReferenceCheckLabel('取引先登録'),
      message: complianceToReferenceCheckLabel(
        '取引先と関連ワードが既に登録済みのため、新規登録できませんでした。',
      ),
    },
  },
  {
    code: ErrorCode.ERROR_CHECK_IN_PROGRESS,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: 'チェック実行',
      message: complianceToReferenceCheckLabel(
        '現在、チェック実行中の取引先があるため実行できませんでした。\n チェック結果画面で判定中の取引先がなくなり次第、実行できます。',
      ),
    },
  },
  {
    code: ErrorCode.ERROR_PROXY_LOGIN,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: '全件チェック',
      message: '権限がありません。',
    },
  },
  {
    code: ErrorCode.ERROR_FOLDER_IS_EMPTY,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: '全件チェック',
      message: complianceToReferenceCheckLabel('指定したフォルダに紐づく取引先はありません。'),
    },
  },
  {
    code: ErrorCode.ERROR_CREDIT_DOWNLOAD_IN_PROGRESS,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: '与信ダウンロード実行',
      message: complianceToReferenceCheckLabel(
        '現在、与信情報ダウンロード中の取引先があるため実行できませんでした。',
      ),
    },
  },
  {
    code: ErrorCode.ERROR_TITLE_ALREADY_EXISTS,
    type: 'reject',
  },
  {
    code: ErrorCode.ERROR_ASSOCIATED_WITH_CUSTOMER,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: 'チェック条件削除',
      message: complianceToReferenceCheckLabel(
        '紐づけされている取引先が存在するため削除できません。',
      ),
    },
  },
  {
    code: ErrorCode.ERROR_CUSTOMER_DOES_NOT_EXIST,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: 'チェック実行',
      message: complianceToReferenceCheckLabel(
        '取引先が削除されている可能性があり、チェック実行できません。\n 再度、取引先の登録をお願いいたします。',
      ),
    },
  },
  {
    code: ErrorCode.ERROR_ALREADY_RE_EXECUTION,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: 'チェック再実行',
      message: 'すでに再実行済みです',
    },
  },
  {
    code: ErrorCode.ERROR_CDATA_CONNECTION,
    type: 'dialog',
    messageDialog: {
      open: true,
      title: '外部サービス接続',
      message: 'RoboRoboへの登録は正常に行われましたが、\n 外部サービスへの接続に失敗しました。',
    },
  },
];

export const axiosErrorHandle = (
  error: AxiosError<ErrorResponseType>,
): ErrorMessageType | undefined => {
  const statusCode = error.response?.status ?? 0;
  const errorDetail = error.response?.data;
  if (statusCode === 401) {
    return {
      type: 'authorizeRefresh',
    };
  }
  if (errorDetail && errorDetail?.errors) {
    const customErrorMessage = (error.config.errorMessages ?? []).find((e) =>
      errorDetail?.errors.every(({ code }) => code === e.code),
    );
    if (customErrorMessage) return customErrorMessage;
    const target = errorMessages.find((e) =>
      errorDetail?.errors.every(({ code }) => code === e.code),
    );
    if (target) return target;
  }

  switch (true) {
    case statusCode === 500:
      return {
        type: 'dialog',
        messageDialog: {
          open: true,
          title: 'システムエラー',
          width: 500,
          contents: (
            <MessageBodyStyle>
              <MessageLabelStyle>
                エラーが発生しました。
                <br />
                RoboRoboカスタマーサクセスチームにご連絡ください。
                <br />
                <a
                  href={`mailto:${import.meta.env.VITE_CS_CONTACT_MAIL_ADDRESS}`}
                  style={{
                    textDecoration: 'none',
                    color: styles.color.blue,
                  }}
                  rel="noopener noreferrer"
                >
                  {import.meta.env.VITE_CS_CONTACT_MAIL_ADDRESS}
                </a>
              </MessageLabelStyle>
            </MessageBodyStyle>
          ),
        },
      };
    case statusCode >= 400 && statusCode < 500:
    default:
      return {
        type: 'reject',
      };
  }
};
