import { paths } from '@/shared/routes';

export const functionType = {
  manualVideoPlay: 8,
};
export const actionType = {
  customers: 5,
  checkResults: 7,
  checkResultDetail: 8,
};
export const oldPage = {
  customers: '/riskCheck/register',
  checkResults: '/riskCheck/searchResult',
  checkResultDetail: '/riskCheck/detail',
};
export const functionUsage = {
  [paths.customers]: {
    page: oldPage.customers,
    actionType: actionType.customers,
  },
  [paths.checkResults]: {
    page: oldPage.checkResults,
    actionType: actionType.checkResults,
  },
  [paths.checkResultDetail]: {
    page: oldPage.checkResultDetail,
    actionType: actionType.checkResultDetail,
  },
} as const;
