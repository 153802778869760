import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { TableResultType } from '@/shared/types/api.type';
import {
  CreditManagementGetResponseType,
  ExecutionCreditManagementDownloadPostRequestType,
} from '@/shared/types/api/api.creditManagement';

export const searchCreditManagements = [
  rest.get<TableResultType<CreditManagementGetResponseType[]>>(
    `${config.baseURL}/group/:group_id/customer/:customer_id/credit-management-execute-result`,
    (req, res, ctx) => {
      const { group_id, customer_id, credit_management_id } = req.params;
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const result: CreditManagementGetResponseType[] = Array.from({ length: 10 }).map(
        (i, index) => {
          return {
            creditManagementExecuteResultId: index,
            gSearchId: faker.word.noun(),
            companyName: faker.company.name(),
            address: faker.word.noun(),
            industry: faker.word.noun(),
            fiscalEndDate: faker.date.recent(index).toDateString(),
          };
        },
      );
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: result.slice(offset, offset + limit),
        }),
      );
    },
  ),
];

export const creditExecution = [
  rest.post<Omit<ExecutionCreditManagementDownloadPostRequestType, 'checkResultId'>>(
    `${config.baseURL}/execution/credit-management-downloads`,
    (req, res, ctx) => {
      const result = { customerId: 1 };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
