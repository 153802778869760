import { axiosInstance } from '@/shared/api/axios';
import {
  CheckNoticeSettingDeleteRequestType,
  CheckNoticeSettingDeleteResponseType,
  CheckNoticeSettingGetResponseType,
  CheckNoticeSettingPostRequestType,
  CheckNoticeSettingPutRequestType,
  CheckNoticeSettingPutResponseType,
} from '@/shared/types/api/api.checkNoticeSetting';

export const getCheckNoticeSetting = async (): Promise<CheckNoticeSettingGetResponseType> => {
  const { data } = await axiosInstance.get<CheckNoticeSettingGetResponseType>(
    `/check-notice-setting`,
  );
  return data;
};

export const createCheckNoticeSetting = async ({
  dateNumber,
  dateUnit,
}: CheckNoticeSettingPostRequestType): Promise<CheckNoticeSettingPutRequestType> => {
  const { data } = await axiosInstance.post(`/check-notice-setting`, {
    dateNumber,
    dateUnit,
  });

  return data;
};

export const updateCheckNoticeSetting = async ({
  checkedAtNoticeId,
  dateNumber,
  dateUnit,
}: CheckNoticeSettingPutRequestType): Promise<CheckNoticeSettingPutResponseType> => {
  const { data } = await axiosInstance.put(`/check-notice-setting/${checkedAtNoticeId}`, {
    dateNumber,
    dateUnit,
  });
  return data;
};

export const deleteCheckNoticeSetting = async ({
  checkedAtNoticeId,
}: CheckNoticeSettingDeleteRequestType): Promise<CheckNoticeSettingDeleteResponseType> => {
  const { data } = await axiosInstance.post(`/check-notice-setting/delete`, {
    checkNoticeSettingId: checkedAtNoticeId,
  });
  return data;
};
