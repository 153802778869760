import React, { useState, useEffect } from 'react';

import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

import { ButtonContainerStyle } from '@/components/ScrollTopButton/css';

type Props = {
  showBelow: number;
  targetRef?: React.RefObject<HTMLDivElement>;
};

const ScrollTopButton: React.FC<Props> = ({ showBelow, targetRef }) => {
  // TODO 使うページ使わないページ分岐すること
  const [show, setShow] = useState(false);
  const handleScroll = () => {
    if (!targetRef?.current) return;
    const newPageOffsetTop = targetRef?.current.scrollTop;
    if (newPageOffsetTop > showBelow) {
      if (!show) setShow(true);
    } else if (show) setShow(false);
  };

  const handleClick = () => {
    if (!targetRef?.current) return;
    targetRef.current.scrollTo({ top: 0, behavior: `smooth` });
  };

  useEffect(() => {
    const target = targetRef?.current;
    const watchScroll = () => {
      if (!target) return;
      target.addEventListener('scroll', handleScroll);
    };
    watchScroll();
    return () => {
      if (!target) return;
      target.removeEventListener('scroll', handleScroll);
    };
  });
  return (
    <div>
      {show && (
        <ButtonContainerStyle onClick={handleClick}>
          <KeyboardArrowUpOutlinedIcon fontSize="large" style={{ margin: 'auto' }} />
        </ButtonContainerStyle>
      )}
    </div>
  );
};
export default ScrollTopButton;
