import jwtDecode from 'jwt-decode';
import { AtomEffect } from 'recoil';

import { AuthType } from '@/shared/types/common.type';
import { jsonParse } from '@/shared/utils/json';

export const localStorageAllEffect: <T>(key: string, prefix?: string) => AtomEffect<T> =
  (key: string, prefix?: string) =>
  ({ setSelf, onSet }) => {
    if (typeof window === 'undefined') return;
    const targetKey = prefix ? `${prefix}${key}` : key;
    const savedValue = localStorage.getItem(targetKey);
    if (savedValue != null) {
      setSelf(jsonParse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(targetKey)
        : localStorage.setItem(targetKey, JSON.stringify(newValue));
    });
  };

export const localStorageEffect: <T>(keys: string[], prefix?: string) => AtomEffect<T> =
  (keys: string[], prefix?: string) =>
  ({ setSelf, onSet }) => {
    if (typeof window === 'undefined') return;
    const saveValues: any = Object.fromEntries(
      keys
        .map((key) => {
          const targetKey = prefix ? `${prefix}${key}` : key;
          const savedValue = localStorage.getItem(targetKey);
          if (savedValue !== null) {
            return [targetKey, jsonParse(savedValue)];
          }
          return null;
        })
        .filter((value) => value !== null) as [string, string][],
    );
    if (Object.keys(saveValues).length > 0) {
      setSelf(saveValues);
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? keys.map((key) => localStorage.removeItem(prefix ? `${prefix}${key}` : key))
        : keys.map((key) =>
            localStorage.setItem(
              prefix ? `${prefix}${key}` : key,
              typeof newValue[key] === 'object' ? JSON.stringify(newValue[key]) : newValue[key],
            ),
          );
    });
  };

export const localStorageAuthInformationEffect: (
  key: string,
  prefix?: string,
) => AtomEffect<Partial<AuthType>> =
  (key: string, prefix?: string) =>
  ({ setSelf }) => {
    if (typeof window === 'undefined') return;
    const targetKey = prefix ? `${prefix}${key}` : key;
    const savedValue = localStorage.getItem(targetKey);
    if (savedValue != null) {
      const decodedAccessToken: AuthType = jwtDecode(savedValue);
      setSelf(decodedAccessToken);
      // setSelf({
      //   ...decodedAccessToken,
      //   authority: authCode.authority.OWNER,
      //   useManageMenuFlg: false,
      //   contract: {
      //     ...decodedAccessToken.contract,
      //     type: authCode.contractType.NET_PAPER_CREDIT,
      //     unitType: authCode.contractUnitType.PAY_PER_USE,
      //     paymentType: authCode.paymentType.PAID,
      //     unitLimit: 15,
      //   },
      // });
    }
  };
