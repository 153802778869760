import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const SearchBarStyle = styled.div`
  display: flex;
  align-items: end;
`;

type SearchButtonProps = {
  iconBackgroundColor?: string;
};

export const SearchButtonStyle = styled.div<SearchButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 35px;
  background-color: ${({ iconBackgroundColor }) => iconBackgroundColor || Style.color.grey};
  cursor: pointer;
  border-radius: 4px;
  margin-left: 3px;
`;
