import dayjs from 'dayjs';

import { SelectOption } from '@/shared/components/Select';

export const convertToString = (date: Date | string, format: string): string => {
  return dayjs(date).format(format);
};

export const isAfter = (
  start?: Date | string,
  end?: Date | string,
  unit?: dayjs.OpUnitType,
): boolean => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  return startDate.isAfter(endDate, unit ?? 'days');
};

export const isSameOrAfter = (
  start?: Date | string,
  end?: Date | string,
  unit?: dayjs.OpUnitType,
): boolean => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  return startDate.isSame(endDate, unit ?? 'days') || startDate.isAfter(endDate, unit ?? 'days');
};
export const gapBetweenTwoDates = (start: Date, end: Date, interval: IntervalType) =>
  dayjs(end).diff(start, interval);

export const dateFormat = {
  yyyy_slash_mm_slash_dd_hh_mm: 'YYYY/MM/DD HH:mm',
  yyyy_slash_mm_slash_dd: 'YYYY/MM/DD',
  yyyy_slash_mm: 'YYYY/MM',
  yyyy_dot_mm_dot_dd: 'YYYY.MM.DD',
  yyyy_hyphen_mm_hyphen_dd: 'YYYY-MM-DD',
  yyyy_hyphen_mm: 'YYYY-MM',
  yyyy_mm_dd: 'YYYYMMDD',
  yyyy_mm_dd_hh_mm_ss: 'YYYYMMDDhhmmss',
};

export const convertExcelDateToJSDate = (date: number) =>
  new Date(Math.round((date - 25569) * 86400 * 1000));

export type IntervalType =
  | 'millisecond'
  | 'second'
  | 'minute'
  | 'hour'
  | 'day'
  | 'week'
  | 'month'
  | 'year';

export const dateRange = (
  start: Date | number,
  end: Date | number,
  interval: IntervalType,
  // asUnixTimestampsMS = false,
  format: string = '',
): (string | Date)[] => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const diffInUnits = endDate.diff(startDate, interval);
  return Array.from(Array(diffInUnits + 1).keys()).map((i) => {
    const date = startDate.add(i, interval).toDate();
    return !format ? date : convertToString(date, format);
  });
};

export const dateRangeForSelectOption = (
  start: Date | number,
  end: Date | number,
  interval: IntervalType,
  format: string = '',
): SelectOption[] => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const diffInUnits = endDate.diff(startDate, interval);
  return Array.from(Array(diffInUnits + 1).keys()).map((i) => {
    const date = startDate.add(i, interval).toDate();
    return {
      key: convertToString(date, format),
      value: convertToString(date, format),
    };
  });
};

export const dateRangeForStringList = (
  start: Date | number,
  end: Date | number,
  interval: IntervalType,
  format: string = '',
): string[] => {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const diffInUnits = endDate.diff(startDate, interval);
  return Array.from(Array(diffInUnits + 1).keys()).map((i) => {
    const date = startDate.add(i, interval).toDate();
    return convertToString(date, format);
  });
};
