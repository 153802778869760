import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  CautionCustomerPostRequestType,
  CautionCustomerPostResponseType,
  CautionCustomerDeleteRequestType,
  CautionCustomerDeleteResponseType,
  CautionCustomerGetRequestType,
  CautionCustomerGetResponseType,
  CautionClassificationGetResponseType,
  CautionCustomerBulkUploadPostRequestType,
  CautionCustomerBulkUploadPostResponseType,
} from '@/shared/types/api/api.cautionCustomer';
import { replaceAllSpaceToHalfSpace } from '@/shared/utils/space';

export const searchCautionCustomers = async ({
  pageSize,
  pageIndex,
  names,
  exactMatchSearchName,
}: CautionCustomerGetRequestType): Promise<TableResultType<CautionCustomerGetResponseType[]>> => {
  const { data } = await axiosInstance.post<TableResultType<CautionCustomerGetResponseType[]>>(
    `/caution/search`,
    {
      names: names
        ? replaceAllSpaceToHalfSpace(names)
            .split(' ')
            .filter((d) => d !== '')
        : [],
      exactMatchSearchName,
    },
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
      },
    },
  );
  return data;
};

export const createCautionCustomers = async ({
  customerName,
  representativeName,
  classification,
  address,
  headquarters,
  registerReason,
  notes,
  listRegisteredAt,
  removeSpaceInName,
}: CautionCustomerPostRequestType): Promise<CautionCustomerPostResponseType> => {
  const { data } = await axiosInstance.post<CautionCustomerPostResponseType>(
    `/caution/individual`,
    {
      customerName,
      representativeName,
      classification,
      address,
      headquarters,
      registerReason,
      notes,
      listRegisteredAt,
      removeSpaceInName,
    },
  );

  return data;
};

export const bulkUpdateCautionCustomers = async ({
  cautionCustomers,
  removeSpaceInName,
}: CautionCustomerBulkUploadPostRequestType): Promise<CautionCustomerBulkUploadPostResponseType> => {
  const { data } = await axiosInstance.post<CautionCustomerBulkUploadPostResponseType>(
    `/caution/bulk`,
    {
      cautionCustomers,
      removeSpaceInName,
    },
    {
      timeout: 300000,
    },
  );

  return data;
};

export const updateCautionCustomers = async ({
  id,
  no,
  customerName,
  representativeName,
  classification,
  address,
  headquarters,
  registerReason,
  notes,
  listRegisteredAt,
  removeSpaceInName,
}: CautionCustomerPostRequestType): Promise<CautionCustomerPostResponseType> => {
  const { data } = await axiosInstance.put<CautionCustomerPostResponseType>(`/caution/${id}`, {
    no,
    customerName,
    representativeName,
    classification,
    address,
    headquarters,
    registerReason,
    notes,
    listRegisteredAt,
    removeSpaceInName,
  });

  return data;
};

export const deleteCautionCustomers = async ({
  cautionCustomerIds,
}: CautionCustomerDeleteRequestType): Promise<CautionCustomerDeleteResponseType> => {
  const { data } = await axiosInstance.post<CautionCustomerDeleteResponseType>(`/caution/delete`, {
    cautionCustomerIds,
  });

  return data;
};

export const getCautionClassification = async (): Promise<
  TableResultType<CautionClassificationGetResponseType[]>
> => {
  const { data } = await axiosInstance.get<TableResultType<CautionClassificationGetResponseType[]>>(
    `/caution/classification`,
    {},
  );
  return data;
};
