import dayjs from 'dayjs';

import { axiosInstance } from '@/shared/api/axios';
import { usageCountGetResponseType } from '@/shared/types/api/api.usageCount';
import { dateFormat } from '@/shared/utils/date';

export const getUsageCount = async (): Promise<usageCountGetResponseType> => {
  const startDate = dayjs().startOf('month').format(dateFormat.yyyy_slash_mm_slash_dd);
  const endDate = dayjs().endOf('month').format(dateFormat.yyyy_slash_mm_slash_dd);
  const { data } = await axiosInstance.get<usageCountGetResponseType>('/usage-count', {
    params: {
      startDate,
      endDate,
    },
  });
  return data;
};
