import React, { useMemo } from 'react';

import styled from '@emotion/styled';
import { useRecoilValue } from 'recoil';

import { currentRouteSelector } from '@/recoil/selectors/currentRouteSeletcor';
import Styles from '@/shared/styles';
import { RoutesType } from '@/shared/types/route.type';

const Footer: React.FC = () => {
  const FooterStyle = styled.div`
    height: 60px;
    background-color: ${Styles.color.footerBG};
  `;

  const currentRoute = useRecoilValue<RoutesType>(currentRouteSelector);
  return useMemo(
    () =>
      currentRoute.hasFooter ? (
        <FooterStyle>
          <div> footer</div>
        </FooterStyle>
      ) : (
        <div />
      ),
    [currentRoute.hasFooter, FooterStyle],
  );
};

export default Footer;
