import { axiosInstance } from '@/shared/api/axios';
import { selectOption } from '@/shared/constants/selectOption';
import {
  FoldersGetRequestType,
  FoldersGetResponseType,
  FoldersType,
} from '@/shared/types/api/api.folders';

export const getFolders = async ({ groupId }: FoldersGetRequestType): Promise<FoldersType> => {
  const { data } = await axiosInstance.get<FoldersGetResponseType[]>(`group/${groupId}/folders`);
  return {
    folders: [
      {
        key: '全て',
        value: selectOption.All,
      },
      ...data.map((item) => ({
        key: item.folderName,
        value: item.folderId,
      })),
      {
        key: '未設定',
        value: selectOption.NONE_SELECT,
      },
    ],
  };
};
