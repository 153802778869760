import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { GroupsGetResponseType } from '@/shared/types/api/api.groups';

export const group = [
  // rest.get<GroupsGetResponseType[]>(`${config.baseURL}/groups`, async (req, res, ctx) => {
  rest.get<GroupsGetResponseType[]>(`${config.baseURL}/groups`, async (req, res, ctx) => {
    return res(
      ctx.delay(delayTime),
      ctx.status(200),
      ctx.json([
        {
          groupName: 'オープンアソシエイツ1',
          groupId: 1,
        },
        {
          groupName: 'オープンアソシエイツ2',
          groupId: 2,
        },
        {
          groupName: 'オープンアソシエイツ3',
          groupId: 3,
        },
        {
          groupName: 'オープンアソシエイツ4',
          groupId: 4,
        },
      ]),
    );
  }),
];
