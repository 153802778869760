import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import NaviBarFooter from './NavigationFooter';

import logo from '@/assets/logo-yoko.png';
import {
  NaviStyle,
  LogoStyle,
  MenuListStyle,
  MenuItemStyle,
  MenuNameStyle,
} from '@/components/Navigation/css';
import { authInformationState } from '@/recoil/atoms/authInformationState';
import { currentRouteState } from '@/recoil/atoms/currentRouteState';
import { blacklistRestrictionDialogSelector } from '@/recoil/selectors/blacklistRestrictionDialogSelector';
import { functionalRestrictionDialogSelector } from '@/recoil/selectors/functionalRestrictionDialogSelector';
import { searchWordSelector } from '@/recoil/selectors/searchwordSelector';
import { authCode } from '@/shared/constants/code';
import styles from '@/shared/styles';
import { RoutesType } from '@/shared/types/route.type';

type PropsType = {
  routes: RoutesType[];
};

const NaviBar: React.FC<PropsType> = ({ routes }) => {
  const location = useLocation();
  const setCurrentRouteState = useSetRecoilState(currentRouteState);
  const setSearchWordState = useSetRecoilState(searchWordSelector);
  const menuRoutes = useMemo(() => routes.filter((route) => route.showMenu), [routes]);
  const { contract, hasOwnerApiToken, useBlacklistFlg } = useRecoilValue(authInformationState);

  // 従量課金でapiトークンがないorフリーミアムプランの場合は制限
  const isFunctionalRestriction = useMemo(
    () =>
      (contract?.unitType === authCode.contractUnitType.PAY_PER_USE && !hasOwnerApiToken) ||
      contract?.paymentType === authCode.paymentType.FREEMIUM,
    [contract?.unitType, contract?.paymentType, hasOwnerApiToken],
  );

  const setFunctionalRestrictionDialogState = useSetRecoilState(
    functionalRestrictionDialogSelector,
  );
  const setBlacklistRestrictionDialogState = useSetRecoilState(blacklistRestrictionDialogSelector);
  const handelClear = (event: any) => {
    event.preventDefault();
  };
  const openFunctionalRestrictionDialog = useCallback(() => {
    setFunctionalRestrictionDialogState({
      open: true,
    });
  }, [setFunctionalRestrictionDialogState]);
  const openBlacklistRestrictionDialog = useCallback(() => {
    setBlacklistRestrictionDialogState({
      open: true,
    });
  }, [setBlacklistRestrictionDialogState]);
  const clickMenu = useCallback(
    (selectedRoute: RoutesType) => {
      setCurrentRouteState(selectedRoute);
      setSearchWordState('');
    },
    [setCurrentRouteState, setSearchWordState],
  );

  return (
    <NaviStyle>
      <LogoStyle href={import.meta.env.VITE_PORTAL_TOP_URL}>
        <img alt="logo" src={logo} width="160" height="31" />
      </LogoStyle>
      <MenuListStyle>
        {menuRoutes.map((route) => (
          <Link
            key={route.path}
            to={route.path}
            style={{ textDecoration: 'none', color: styles.color.blue }}
            onClick={(event) => {
              if (isFunctionalRestriction && route.name === 'フォルダ管理') {
                handelClear(event);
                openFunctionalRestrictionDialog();
              } else if (route.name === 'ブラックリスト登録') {
                if (isFunctionalRestriction) {
                  handelClear(event);
                  openFunctionalRestrictionDialog();
                } else if (!useBlacklistFlg) {
                  handelClear(event);
                  openBlacklistRestrictionDialog();
                } else {
                  clickMenu(route);
                }
              } else {
                clickMenu(route);
              }
            }}
          >
            <MenuItemStyle selected={location.pathname === route.path}>
              <route.icon fontSize="large" />
              <MenuNameStyle>{route.name}</MenuNameStyle>
            </MenuItemStyle>
          </Link>
        ))}
      </MenuListStyle>
      <NaviBarFooter />
    </NaviStyle>
  );
};

export default NaviBar;
