import { atom } from 'recoil';

import {
  localStorageAuthInformationEffect,
  localStorageEffect,
} from '@/recoil/effect/localStorageEffect';
import { constants } from '@/shared/constants';
import { AuthPostResponseType } from '@/shared/types/api/api.auth';
import { AuthType } from '@/shared/types/common.type';

export const tokenState = atom<AuthPostResponseType>({
  key: 'tokenState',
  default: {
    access_token: '',
    refresh_token: '',
    token_type: '',
    expires_in: 0,
    scope: '',
  },
  // effects_UNSTABLE: [],
  effects: [
    localStorageEffect([
      constants.localstorage().authInformation.accessToken,
      constants.localstorage().authInformation.refreshToken,
    ]),
  ],
});

export const authInformationState = atom<Partial<AuthType>>({
  key: 'authInformationState',
  default: {},
  effects: [
    localStorageAuthInformationEffect(constants.localstorage().authInformation.accessToken),
  ],
});
