import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { convertToString, dateFormat } from '@/shared/utils/date';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { FoldersType } from '@/shared/types/api/api.folders';

export const folder = [
  rest.get<FoldersType>(`${config.baseURL}/group/:groupId/folders`, async (req, res, ctx) => {
    return res(
      ctx.delay(delayTime),
      ctx.status(200),
      ctx.json(
        [1, 2].map((i) => {
          return {
            folderName:
              'コンプライアンスチェックテンプレート_' +
              convertToString(
                i % 2 === 0 ? faker.date.recent() : dayjs(faker.date.recent()).add(1, 'd').toDate(),
                dateFormat.yyyy_mm_dd,
              ),
            folderId: i,
          };
        }),
      ),
    );
  }),
];
