import { selector, DefaultValue } from 'recoil';

import { functionalRestrictionDialogState } from '../atoms/functionalRestrictionDialogState';

import { FunctionalRestrictionDialogType } from '@/shared/types/common.type';

export const functionalRestrictionDialogSelector = selector<FunctionalRestrictionDialogType>({
  key: 'functionalRestrictionDialogSelector',
  get: ({ get }) => get(functionalRestrictionDialogState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue.open) {
      reset(functionalRestrictionDialogState);
    }
    set(functionalRestrictionDialogState, newValue);
  },
});
