import React, { useEffect } from 'react';

import { FallbackProps } from 'react-error-boundary';

const Error: React.FC<FallbackProps> = ({ error }) => {
  useEffect(() => {
    window.location.href = import.meta.env.VITE_PORTAL_ERROR_URL;
  }, []);
  return <div />;
};

export default Error;
