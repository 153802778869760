import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  BlacklistPostRequestType,
  BlacklistPostResponseType,
  BlacklistDeleteRequestType,
  BlacklistDeleteResponseType,
  BlacklistGetRequestType,
  BlacklistGetResponseType,
  BlacklistClassificationGetResponseType,
  BlacklistBulkUploadPostRequestType,
  BlacklistBulkUploadPostResponseType,
  BlacklistClassificationBulkUploadPostRequestType,
  BlacklistClassificationBulkUploadPostResponseType,
  BlacklistSettingGetResponseType,
  BlacklistFileDownloadPostRequestType,
  BlacklistFileDownloadResponseType,
} from '@/shared/types/api/api.blacklist';
import { replaceAllSpaceToHalfSpace } from '@/shared/utils/space';

export const searchBlacklist = async ({
  pageSize,
  pageIndex,
  names,
  exactMatchSearchName,
}: BlacklistGetRequestType): Promise<TableResultType<BlacklistGetResponseType[]>> => {
  const { data } = await axiosInstance.post<TableResultType<BlacklistGetResponseType[]>>(
    `/blacklist/search`,
    {
      names: names
        ? replaceAllSpaceToHalfSpace(names)
            .split(' ')
            .filter((d) => d !== '')
        : [],
      exactMatchSearchName,
    },
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
      },
    },
  );
  return data;
};

export const createBlacklist = async ({
  customerName,
  representativeName,
  classificationId,
  address,
  registerReason,
  notes,
  listRegisteredAt,
  removeSpaceInName,
}: BlacklistPostRequestType): Promise<BlacklistPostResponseType> => {
  const { data } = await axiosInstance.post<BlacklistPostResponseType>(`/blacklist/individual`, {
    customerName,
    representativeName,
    classificationId,
    address,
    registerReason,
    notes,
    listRegisteredAt,
    removeSpaceInName,
  });

  return data;
};

export const bulkUpdateBlacklist = async ({
  blacklists,
  removeSpaceInName,
}: BlacklistBulkUploadPostRequestType): Promise<BlacklistBulkUploadPostResponseType> => {
  const { data } = await axiosInstance.post<BlacklistBulkUploadPostResponseType>(
    `/blacklist/bulk`,
    {
      blacklists,
      removeSpaceInName,
    },
    {
      timeout: 300000,
    },
  );

  return data;
};

export const updateBlacklist = async ({
  id,
  no,
  customerName,
  representativeName,
  classificationId,
  address,
  registerReason,
  notes,
  listRegisteredAt,
  removeSpaceInName,
}: BlacklistPostRequestType): Promise<BlacklistPostResponseType> => {
  const { data } = await axiosInstance.put<BlacklistPostResponseType>(`/blacklist/${id}`, {
    no,
    customerName,
    representativeName,
    classificationId,
    address,
    registerReason,
    notes,
    listRegisteredAt,
    removeSpaceInName,
  });

  return data;
};

export const deleteBlacklist = async ({
  blacklistIds,
}: BlacklistDeleteRequestType): Promise<BlacklistDeleteResponseType> => {
  const { data } = await axiosInstance.post<BlacklistDeleteResponseType>(`/blacklist/delete`, {
    blacklistIds,
  });

  return data;
};

export const downloadBlacklistFiles = async ({
  groupId,
  blacklistIds,
}: BlacklistFileDownloadPostRequestType): Promise<BlacklistFileDownloadResponseType> => {
  const { data } = await axiosInstance.post<BlacklistFileDownloadResponseType>(
    `/blacklist/group/${groupId}/file`,
    {
      blacklistIds,
    },
  );

  return data;
};

export const getBlacklistClassification = async (): Promise<
  TableResultType<BlacklistClassificationGetResponseType[]>
> => {
  const { data } = await axiosInstance.get<
    TableResultType<BlacklistClassificationGetResponseType[]>
  >(`/blacklist/classification`, {});
  return data;
};

export const bulkUpdateBlacklistClassification = async ({
  blacklistClassifications,
}: BlacklistClassificationBulkUploadPostRequestType): Promise<BlacklistClassificationBulkUploadPostResponseType> => {
  const { data } = await axiosInstance.post<BlacklistClassificationBulkUploadPostResponseType>(
    `/blacklist/bulkClassification`,
    {
      blacklistClassificationList: blacklistClassifications,
    },
    {
      timeout: 300000,
    },
  );

  return data;
};

export const getBlacklistSetting = async (): Promise<BlacklistSettingGetResponseType> => {
  const { data } = await axiosInstance.get<BlacklistSettingGetResponseType>(
    `/blacklist/blacklistSetting`,
    {},
  );
  return data;
};
