import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { convertToString, dateFormat } from '@/shared/utils/date';
import dayjs from 'dayjs';

// export const getFiles = ['all', 's1', 's2', 's3', 's4', 's5', 'none'].map((groupId) => {

export const folderSetting = [
  rest.get(`${config.baseURL}/group/:groupId/folder-setting`, (req, res, ctx) => {
    const limit: number = Number(req.url.searchParams.get('limit'));
    const offset: number = Number(req.url.searchParams.get('offset'));
    // const sortTarget: string = String(req.url.searchParams.get('sortTarget'));
    const folderId: number = Number(req.url.searchParams.get('folderId'));
    // const sortOrder: string = String(req.url.searchParams.get('sortOrder'));
    const searchWord: string | null = req.url.searchParams.get('customerName');

    const result = Array.from({ length: 315 }).map((i, index) => {
      return {
        customerId: index + 1,
        customerName: `${index + 1} :: スタディプログラミングステッパーズ - ${faker.word.noun()} `,
        relativeWord: faker.word.noun(),
        checkedAt: faker.date.recent(),
        folderName:
          'コンプライアンスチェックテンプレート_' +
          convertToString(faker.date.recent(), dateFormat.yyyy_mm_dd),
      };
    });

    let filteredResult = searchWord
      ? result.filter(({ customerName }) => customerName.includes(searchWord))
      : result;

    if (folderId) {
      if (folderId !== 0) {
        const folderNameParam =
          'コンプライアンスチェックテンプレート_' +
          convertToString(
            Number(folderId) % 2 === 0
              ? faker.date.recent()
              : dayjs(faker.date.recent()).add(1, 'd').toDate(),
            dateFormat.yyyy_mm_dd,
          );
        filteredResult = filteredResult.filter(({ folderName }) => {
          return folderName === folderNameParam;
        });
      } else {
        filteredResult = filteredResult.filter(
          ({ folderName }) =>
            ![
              'コンプライアンスチェックテンプレート_' +
                convertToString(faker.date.recent(), dateFormat.yyyy_mm_dd),
              'コンプライアンスチェックテンプレート_' +
                convertToString(
                  dayjs(faker.date.recent()).add(1, 'd').toDate(),
                  dateFormat.yyyy_mm_dd,
                ),
            ].includes(folderName),
        );
      }
    }

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json({
        total: filteredResult.length,
        offset,
        limit,
        rows: filteredResult.slice(offset, offset + limit),
      }),
    );
  }),
];
