import { axiosInstance } from '@/shared/api/axios';
import {
  CheckCountGetResponseType,
  CheckCountHistoryGetRequestType,
  CheckCountHistoryGetResponseType,
  CheckExecutionHistoryGetRequestType,
  CheckExecutionHistoryGetResponseType,
  NoCheckCountGetResponseType,
  NoticeGetResponseType,
  ReducedTimeMinutesGetRequestType,
  ReducedTimeMinutesGetResponseType,
  ReducedTimeMinutesHistoryGetRequestType,
  ReducedTimeMinutesHistoryGetResponseType,
} from '@/shared/types/api/api.dashboard';

export const getNotice = async (): Promise<NoticeGetResponseType[]> => {
  const { data } = await axiosInstance.get<NoticeGetResponseType[]>(`dashboard/notice`);
  return data;
};

export const getCheckCount = async (): Promise<CheckCountGetResponseType> => {
  const { data } = await axiosInstance.get<CheckCountGetResponseType>('dashboard/check-count');
  return data;
};

export const getNoCheckCount = async (): Promise<NoCheckCountGetResponseType> => {
  const { data } = await axiosInstance.get<NoCheckCountGetResponseType>('dashboard/no-check-count');
  return data;
};

export const getCheckCountHistory = async ({
  fromYearMonth,
  toYearMonth,
}: CheckCountHistoryGetRequestType): Promise<CheckCountHistoryGetResponseType> => {
  const { data } = await axiosInstance.get<CheckCountHistoryGetResponseType>(
    `dashboard/check-count-history`,
    {
      params: {
        fromYearMonth,
        toYearMonth,
      },
    },
  );
  return data;
};

export const getCheckExecutionHistory = async ({
  fromYearMonth,
  toYearMonth,
}: CheckExecutionHistoryGetRequestType): Promise<CheckExecutionHistoryGetResponseType> => {
  const { data } = await axiosInstance.get<CheckExecutionHistoryGetResponseType>(
    `dashboard/check-execution-history`,
    {
      params: {
        fromYearMonth,
        toYearMonth,
      },
    },
  );
  return data;
};

export const getReducedTimeHistory = async ({
  fromYearMonth,
  toYearMonth,
}: ReducedTimeMinutesHistoryGetRequestType): Promise<ReducedTimeMinutesHistoryGetResponseType> => {
  const { data } = await axiosInstance.get<ReducedTimeMinutesHistoryGetResponseType>(
    `dashboard/reduced-time-history`,
    {
      params: {
        fromYearMonth,
        toYearMonth,
      },
    },
  );
  return data;
};

export const getReducedTimeMinutes = async ({
  fromYearMonth,
  toYearMonth,
}: ReducedTimeMinutesGetRequestType): Promise<ReducedTimeMinutesGetResponseType> => {
  const { data } = await axiosInstance.get<ReducedTimeMinutesGetResponseType>(
    `dashboard/reduced-time-minutes`,
    {
      params: {
        fromYearMonth,
        toYearMonth,
      },
    },
  );

  return data;
};
