import styled from '@emotion/styled';

import Styles from '@/shared/styles';

export const MessageBodyStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  font-size: 1.8rem;
  font-weight: 600;
`;

export const MessageLabelStyle = styled.div`
  color: ${Styles.color.black};
  margin-top: 10px;
  white-space: pre-line;
`;
