import { axiosInstance } from '@/shared/api/axios';
import { ApiAuthInformationKindType } from '@/shared/constants/code';
import { ApiAuthInformationGetResponseType } from '@/shared/types/api/api.apiAuthInformation';

export const getApiAuthInformation = async (
  apiAuthInformationKindType: ApiAuthInformationKindType,
): Promise<ApiAuthInformationGetResponseType> => {
  const { data } = await axiosInstance.get<ApiAuthInformationGetResponseType>(
    `/api-auth-information`,
    {
      params: {
        kind: apiAuthInformationKindType,
      },
    },
  );
  return data;
};
