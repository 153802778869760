import {
  bulkUpdateBlacklist,
  createBlacklist,
  deleteBlacklist,
  downloadBlacklistFiles,
  searchBlacklist,
  updateBlacklist,
  getBlacklistClassification,
  bulkUpdateBlacklistClassification,
  getBlacklistSetting,
} from './blacklist';
import {
  deleteCautionCustomers,
  searchCautionCustomers,
  getCautionClassification,
  createCautionCustomers,
  updateCautionCustomers,
  bulkUpdateCautionCustomers,
} from './cautionCustomers';

import { getApiAuthInformation } from '@/shared/api/apiAuthInformation';
import {
  connectTest,
  createAuth,
  createColumn,
  createTable,
  deleteApiSetting,
  getColumns,
  getFunctions,
  getList,
  getTables,
  updateAuth,
} from '@/shared/api/apiSetting';
import { authorizeUser, refreshAccessToken } from '@/shared/api/auth';
import {
  getCheckDecisions,
  createCheckDecision,
  updateCheckDecision,
  deleteCheckDecision,
  checkDecisionFileDownload,
  bulkRegisterCheckDecision,
} from '@/shared/api/checkDecision';
import {
  allExecution,
  execution,
  reExecution,
  executionErrorCheck,
} from '@/shared/api/checkExecution';
import {
  getCheckNoticeSetting,
  createCheckNoticeSetting,
  updateCheckNoticeSetting,
  deleteCheckNoticeSetting,
} from '@/shared/api/checkNoticeSetting';
import {
  pdfDownloadCheckResultsDetail,
  downloadCheckResultDetailFiles,
  getCheckResultDetailCheckCondition,
  searchCheckResultDetailList,
  updateConfirmed,
} from '@/shared/api/checkResultDetail';
import {
  bulkOutputExcel,
  bulkUpdateClickAt,
  downloadCheckResultFiles,
  searchCheckResults,
  searchCheckResultsHistory,
  updateClickAt,
} from '@/shared/api/checkResults';
import {
  createCheckConditionSettings,
  deleteCheckConditionSettings,
  getCheckConditionSettings,
  reflectionCheckConditionSettings,
  updateCheckConditionSettings,
} from '@/shared/api/conditionSettings';
import {
  creditExecution,
  creditReExecution,
  searchCreditManagements,
} from '@/shared/api/creditManagement';
import {
  getCreditManagementDownload,
  downloadCreditManagementFiles,
  uploadCreditManagementsFile,
  getCreditManagementExecuteStatus,
  getCreditManagementDownloadsExecuteStatus,
} from '@/shared/api/creditManagementDownload';
import {
  createCustomerInformation,
  getCustomerInformation,
  updateCustomerInformation,
} from '@/shared/api/customerInformation';
import {
  bulkUpdateCustomers,
  customBulkUpdateCustomers,
  duplicateCheckCustomers,
  createCustomers,
  deleteCustomers,
  searchCustomers,
  updateCustomer,
  downloadCustomerFiles,
  getCustomBulkSetting,
} from '@/shared/api/customers';
import {
  getCheckCount,
  getCheckCountHistory,
  getCheckExecutionHistory,
  getNoCheckCount,
  getNotice,
  getReducedTimeHistory,
  getReducedTimeMinutes,
} from '@/shared/api/dashboard';
import { fileUpload } from '@/shared/api/file';
import {
  downloadFilesForFileManagement,
  getFileManagementFiles,
} from '@/shared/api/fileManagement';
import { getFolders } from '@/shared/api/folder';
import {
  createFolderList,
  deleteFolderList,
  getFolderList,
  getFolderSettings,
  updateFolderList,
  updateFolderSetting,
} from '@/shared/api/folderManagement';
import { postFunctionUsage } from '@/shared/api/functionUsage';
import { getGroups } from '@/shared/api/group';
import {
  getNewspaperConditionSettings,
  updateNewspaperConditionSettings,
} from '@/shared/api/newspaperCondition';
import { getUsageCount } from '@/shared/api/usageCount';

export default {
  usageCount: {
    get: getUsageCount,
  },
  functionUsage: {
    post: postFunctionUsage,
  },
  groups: {
    get: getGroups,
  },
  folders: {
    get: getFolders,
  },
  blacklist: {
    search: searchBlacklist,
    create: createBlacklist,
    bulkUpdate: bulkUpdateBlacklist,
    update: updateBlacklist,
    delete: deleteBlacklist,
    download: downloadBlacklistFiles,
    getBlacklistClassification,
    bulkUpdateBlacklistClassification,
    getBlacklistSetting,
  },
  cautionCustomers: {
    search: searchCautionCustomers,
    create: createCautionCustomers,
    bulkUpdate: bulkUpdateCautionCustomers,
    update: updateCautionCustomers,
    delete: deleteCautionCustomers,
    getCautionClassification,
  },
  customers: {
    search: searchCustomers,
    create: createCustomers,
    bulkUpdate: bulkUpdateCustomers,
    customBulkUpdate: customBulkUpdateCustomers,
    duplicateCheck: duplicateCheckCustomers,
    update: updateCustomer,
    delete: deleteCustomers,
    download: downloadCustomerFiles,
    bulkSetting: getCustomBulkSetting,
  },
  checkNoticeSetting: {
    get: getCheckNoticeSetting,
    create: createCheckNoticeSetting,
    update: updateCheckNoticeSetting,
    delete: deleteCheckNoticeSetting,
  },
  checkExecution: {
    execution,
    allExecution,
    reExecution,
    errorCheck: executionErrorCheck,
  },
  customerInformation: {
    get: getCustomerInformation,
    create: createCustomerInformation,
    update: updateCustomerInformation,
  },
  checkConditionSettings: {
    get: getCheckConditionSettings,
    create: createCheckConditionSettings,
    update: updateCheckConditionSettings,
    delete: deleteCheckConditionSettings,
    reflection: reflectionCheckConditionSettings,
  },
  newspaperCondition: {
    get: getNewspaperConditionSettings,
    update: updateNewspaperConditionSettings,
  },
  checkResults: {
    search: searchCheckResults,
    getHistory: searchCheckResultsHistory,
    updateClickAt,
    download: downloadCheckResultFiles,
    bulkUpdateClickAt,
    bulkOutputExcel,
  },
  checkResultDetail: {
    getCheckCondition: getCheckResultDetailCheckCondition,
    search: searchCheckResultDetailList,
    download: downloadCheckResultDetailFiles,
    singleDownload: pdfDownloadCheckResultsDetail,
    updateConfirmed,
  },
  checkDecision: {
    get: getCheckDecisions,
    create: createCheckDecision,
    update: updateCheckDecision,
    delete: deleteCheckDecision,
    download: checkDecisionFileDownload,
    bulkRegister: bulkRegisterCheckDecision,
  },
  folderSettings: {
    get: getFolderSettings,
    update: updateFolderSetting,
  },
  folderList: {
    get: getFolderList,
    create: createFolderList,
    update: updateFolderList,
    delete: deleteFolderList,
  },
  fileManagements: {
    get: getFileManagementFiles,
    download: downloadFilesForFileManagement,
  },
  creditManagementDownload: {
    get: getCreditManagementDownload,
    upload: uploadCreditManagementsFile,
    download: downloadCreditManagementFiles,
    getExecutionStatus: getCreditManagementExecuteStatus,
    getDownloadsExecutionStatus: getCreditManagementDownloadsExecuteStatus,
  },
  creditManagement: {
    search: searchCreditManagements,
    execution: creditExecution,
    reExecution: creditReExecution,
  },
  auth: {
    authorizeUser,
    refreshAccessToken,
  },
  dashboard: {
    notice: {
      get: getNotice,
    },
    checkCountHistory: {
      get: getCheckCountHistory,
      getExecution: getCheckExecutionHistory,
    },
    reducedTime: {
      get: {
        minutes: getReducedTimeMinutes,
        history: getReducedTimeHistory,
      },
    },
    checkCount: {
      get: getCheckCount,
    },
    noCheckCount: {
      get: getNoCheckCount,
    },
  },
  apiAuthInformation: {
    get: getApiAuthInformation,
  },
  file: {
    // NOTE: S3 upload用
    upload: fileUpload,
  },
  apiSetting: {
    setting: {
      list: getList,
      delete: deleteApiSetting,
    },
    auth: {
      test: connectTest,
      create: createAuth,
      update: updateAuth,
    },
    functions: {
      list: getFunctions,
    },
    tables: {
      list: getTables,
      create: createTable,
    },
    columns: {
      list: getColumns,
      create: createColumn,
    },
  },
};
