import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { CustomerInformationGetResponseType } from '@/shared/types/api/api.customerInformation';

export const customerInformation = [
  rest.get(
    `${config.baseURL}/group/:group_id/customer/:customer_id/information`,
    (req, res, ctx) => {
      const { group_id, customer_id } = req.params;
      const result: CustomerInformationGetResponseType = {
        customerInformationId: faker.datatype.number(),
        customerName: `${group_id}:${customer_id} :: スタディプログラミングステッパーズ - ${faker.word.noun()}`,
        companyNumber: String(faker.datatype.number({ min: 0, max: 100 })),
        representativeName: faker.word.noun(),
        address: faker.word.noun(),
        email: faker.internet.email(),
        phoneNumber: faker.phone.number('###-####-####'),
        capital: faker.word.noun(),
        employeeCount: String(faker.datatype.number({ min: 0, max: 100 })),
        url: faker.internet.url(),
        memo: faker.word.noun(),
      };

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.post(
    `${config.baseURL}/group/:group_id/customer/:customer_id/information`,
    (req, res, ctx) => {
      const result = {
        customerInformationId: faker.datatype.uuid(),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.put(
    `${config.baseURL}/group/:group_id/customer/:customer_id/information/:customerInformationId`,
    (req, res, ctx) => {
      const result = {
        customerInformationId: faker.datatype.uuid(),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
