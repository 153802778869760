import styled from '@emotion/styled';

import Styles from '@/shared/styles';

export const LayoutFull = styled.div`
  display: flex;
  height: 100vh;
  font-family: ${Styles.default.fontFamily};
  font-size: 1.6rem;
`;

export const LayoutBody = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Styles.color.bodyBG};
  width: calc(100% - 200px);
  min-width: 1200px;
`;

type LayoutContentsProps = {
  margin: number;
  hasFooter: boolean;
};
export const LayoutContents = styled.div<LayoutContentsProps>`
  height: calc(100% - ${({ margin, hasFooter }) => (hasFooter ? 100 : 40) + margin * 2}px);
  overflow: auto;
  margin-top: ${({ margin }) => margin}px;
  margin-left: ${({ margin }) => margin}px;
  margin-right: ${({ margin }) => margin}px;
  @media print {
    overflow: visible;
  }
`;
