import React from 'react';

import { useRecoilValue } from 'recoil';

import InstructorVideo from '@/components/InstructorVideo';
import SearchBar from '@/components/SearchBar';
import { HeaderStyle, NameStyle, TitleStyle } from '@/components/TopHeader/css';
import { currentRouteSelector } from '@/recoil/selectors/currentRouteSeletcor';
import styles from '@/shared/styles';
import { RoutesType } from '@/shared/types/route.type';

const TopHeader: React.FC = () => {
  const currentRoute = useRecoilValue<RoutesType>(currentRouteSelector);
  return (
    <HeaderStyle>
      <TitleStyle>
        <currentRoute.icon sx={{ fontSize: '3rem' }} />
        <NameStyle>{currentRoute.name}</NameStyle>
        {!!currentRoute.subTitle && <currentRoute.subTitle />}
        {currentRoute.instructionVideo && <InstructorVideo marginLeft="15px" />}
      </TitleStyle>
      {currentRoute.hasSearchBar && (
        <SearchBar
          iconBackgroundColor={styles.color.skyBlue}
          width={currentRoute.searchBarOption?.width}
        />
      )}
    </HeaderStyle>
  );
};

export default TopHeader;
