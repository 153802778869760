import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 330px;
`;

type TextStyleProp = {
  isAlert?: boolean;
  fontSize?: string;
  fontWeight?: number;
};

export const TextStyle = styled.div<TextStyleProp>`
  font-size: ${({ fontSize }) => fontSize || '2.0rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  color: ${({ isAlert }) => !!isAlert && Style.color.red};
`;

export const TextOuterGroupStyle = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextInnerGroupStyle = styled.div`
  margin-left: 15px;
`;
