import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  CheckDecisionPostRequestType,
  CheckDecisionPostResponseType,
  CheckDecisionGetResponseType,
  CheckDecisionDeleteRequestType,
  CheckDecisionDeleteResponseType,
  CheckDecisionGetRequestType,
  CheckDecisionUpdatePutRequestType,
  CheckDecisionUpdatePutResponseType,
  CheckDecisionFileDownloadGetResponseType,
  CheckDecisionFileDownloadGetRequestType,
  CheckDecisionBulkRegisterPostRequestType,
  CheckDecisionBulkRegisterPostResponseType,
} from '@/shared/types/api/api.checkDecision';

export const getCheckDecisions = async ({
  groupId,
  customerId,
  pageSize,
  pageIndex,
}: CheckDecisionGetRequestType): Promise<TableResultType<CheckDecisionGetResponseType[]>> => {
  const { data } = await axiosInstance.get<TableResultType<CheckDecisionGetResponseType[]>>(
    `/group/${groupId}/check-result/${customerId}/check-decision`, // NOTE:: customerIdここ注意
    {
      params: {
        limit: pageSize ?? undefined,
        offset: pageSize && pageIndex ? (pageIndex - 1) * pageSize : undefined,
      },
    },
  );
  return data;
};

export const createCheckDecision = async (
  groupId: number,
  checkExecutionId: number,
  requestBody: Omit<CheckDecisionPostRequestType, 'checkExecutionId'>,
): Promise<CheckDecisionPostResponseType> => {
  const { data } = await axiosInstance.post<CheckDecisionPostResponseType>(
    `/group/${groupId}/check-result/${checkExecutionId}/check-decision`,
    {
      ...requestBody,
    },
  );
  return data;
};

export const updateCheckDecision = async (
  groupId: number,
  checkDecisionId: number,
  requestBody: Omit<CheckDecisionUpdatePutRequestType, 'checkExecutionId'>,
): Promise<CheckDecisionUpdatePutResponseType> => {
  const { data } = await axiosInstance.put<CheckDecisionUpdatePutResponseType>(
    `/group/${groupId}/check-decision/${checkDecisionId}`,
    {
      ...requestBody,
    },
  );
  return data;
};

export const deleteCheckDecision = async (
  groupId: number,
  requestBody: CheckDecisionDeleteRequestType,
): Promise<CheckDecisionDeleteResponseType> => {
  const { data } = await axiosInstance.post<CheckDecisionDeleteResponseType>(
    `/group/${groupId}/check-decision/delete`,
    {
      ...requestBody,
    },
  );
  return data;
};

export const checkDecisionFileDownload = async ({
  groupId,
  checkDecisionId,
}: CheckDecisionFileDownloadGetRequestType): Promise<CheckDecisionFileDownloadGetResponseType> => {
  const { data } = await axiosInstance.get<CheckDecisionFileDownloadGetResponseType>(
    `/group/${groupId}/check-decision/${checkDecisionId}/file-download`,
  );
  return data;
};

export const bulkRegisterCheckDecision = async (
  groupId: number,
  requestBody: CheckDecisionBulkRegisterPostRequestType,
): Promise<CheckDecisionBulkRegisterPostResponseType> => {
  const { data } = await axiosInstance.post<CheckDecisionBulkRegisterPostResponseType>(
    `/group/${groupId}/check-result/bulk-register`,
    {
      ...requestBody,
    },
  );
  return data;
};
