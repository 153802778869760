export const ErrorCode = {
  // 共通: E00---
  ERROR_NOT_EMPTY: 'E00001', // 値が未入力
  ERROR_MAX_LENGTH: 'E00002', // 文字列最大長エラー
  ERROR_MIN_VALUE: 'E00003', // 最小値エラー
  ERROR_MAX_VALUE: 'E00004', // 最大値エラー
  ERROR_INVALID_EMAIL: 'E00005', // メールアドレスが不正
  ERROR_INVALID_CODE: 'E00006', // コード値が不正
  ERROR_INVALID_DATE: 'E00007', // 日付が不正
  ERROR_INVALID_RANGE: 'E00008', // 値が指定された範囲に無い
  ERROR_RESOURCE_NOT_FOUND: 'E00100', // リソースが見つからない
  ERROR_RESOURCE_DUPLICATE: 'E00101', // リソースが重複している
  // フォルダ管理: E01---
  ERROR_GROUP_NOT_FOUND: 'E01001', // グループが見つからない
  ERROR_DELETE_LINKED_CUSTOMERS: 'E01002', // 取引先が関連づけられたフォルダを削除しようとしたとき
  // 取引先一覧: E02---
  ERROR_DUPLICATE_RELATIVE_WORD: 'E02001', // 関連ワードが重複している
  ERROR_CHECK_IN_PROGRESS: 'E02002', // チェック実行中の取引先ある時にさらにチェック実行をした時
  ERROR_FOLDER_IS_EMPTY: 'E02003', // 全件チェックで空のフォルダを指定した時
  ERROR_PROXY_LOGIN: 'E02004', // 全件チェックで代理ログインではないとき
  ERROR_CREDIT_CHECK_IN_PROGRESS: 'E02005', // 与信調査実行中の取引先がある時にさらに与信調査実行をした時
  // 取引先情報: E03---
  ERROR_CREDIT_DOWNLOAD_IN_PROGRESS: 'E03001', // 与信ダウンロード実行中の時にさらに与信ダウンロードを実行しようとした時
  // ユーザー・グループ情報: E04---
  ERROR_REGISTER_USER_ALREADY_EXISTS: 'E04001', // 登録対象ユーザがすでに存在する
  // チェック条件設定: E05---
  ERROR_DELETE_MASTER: 'E05001', // マスターのチェック条件を削除しようとした時
  ERROR_UPDATE_MASTER: 'E05002', // マスターのチェック条件の「デフォルト条件にする」以外を更新しようとした時
  ERROR_TITLE_ALREADY_EXISTS: 'E05003', // 既に登録済みのチェック条件タイトルと同じものを登録、更新しようとした時
  ERROR_ASSOCIATED_WITH_CUSTOMER: 'E05004', // 取引先が関連づけられたチェック条件を削除しようとしたとき
  // 存在しない（削除済みの）取引先のチェック実行をしようとした時
  ERROR_CUSTOMER_DOES_NOT_EXIST: 'E06001',
  ERROR_EXECUTION_ERROR_IN_CREDIT_DOWNLOAD: 'E06002', // 実行対象の取引先に与信ダウンロードにがある時に与信調査実行をした時
  ERROR_ALREADY_RE_EXECUTION: 'E07001', // 再実行済みの時（別のブラウザで再実行された後に元のブラウザでボタン押下した時）
  // CDATA
  ERROR_CDATA_CONNECTION: 'E0C001', // 外部サービスへの接続に失敗
  // 共通: E00---
  ERROR_CODE_NOT_FOUND: 'E00997', // エラーコードがプロパティから見つからない
  ERROR_DATA_ACCESS: 'E00998', // データアクセス系のランタイムエラー。
  ERROR_APPLICATION: 'E00999', // その他全てのハンドリング出来ていないランタイムエラー
} as const;

export type ErrorCodeType = typeof ErrorCode[keyof typeof ErrorCode];
