import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { CheckNoticeSettingGetResponseType } from '@/shared/types/api/api.checkNoticeSetting';

export const checkNoticeSetting = [
  rest.get<CheckNoticeSettingGetResponseType>(
    `${config.baseURL}/check-notice-setting`,
    (req, res, ctx) => {
      const result = {
        checkedAtNoticeId: faker.datatype.number(),
        dateNumber: faker.datatype.number(1),
        dateUnit: 'WEEK',
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
