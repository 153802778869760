import { group } from '@/shared/mocks/group';
import { folder } from '@/shared/mocks/folder';
import {
  checkResults,
  checkResultsHistory,
  searchCheckResultDetailList,
  getCheckResultDetail,
  bulkUpdateClickAt,
  getCheckResultDetailCheckCondition,
} from '@/shared/mocks/checkResults';
import {
  getCheckDecisions,
  createCheckDecision,
  updateCheckDecision,
  deleteCheckDecision,
  bulkRegisterCheckDecision,
} from '@/shared/mocks/checkDecision';
import { customer } from '@/shared/mocks/customer';
import { checkConditionSetting } from '@/shared/mocks/conditionSetting';
import { folderSetting } from '@/shared/mocks/folderSettings';
import { folderList } from '@/shared/mocks/folderList';
import { getFiles, downloadFiles } from '@/shared/mocks/fileManagement';
import { customerInformation } from '@/shared/mocks/customerInformation';
import {
  downloadCreditManagementFiles,
  getCreditManagementDownload,
  getCreditManagementExecuteStatus,
  uploadCreditManagementsFile,
} from '@/shared/mocks/creditManagementDownload';
import { creditExecution, searchCreditManagements } from '@/shared/mocks/creditManagement';
import { usageCount } from '@/shared/mocks/usageCount';
import { dashboard } from '@/shared/mocks/dashboard';
import { auth } from '@/shared/mocks/auth';
import { newspaperConditionSettings } from '@/shared/mocks/newspaperCondition';
import { checkNoticeSetting } from '@/shared/mocks/checkNoticeSetting';
import { apiSetting } from './apiSetting';

export const delayTime = 1000;

export const handers = [
  ...usageCount,
  ...group,
  ...folder,
  ...customer,
  ...checkConditionSetting,
  ...folderSetting,
  ...folderList,
  ...getFiles,
  ...downloadFiles,
  ...checkResults,
  ...checkResultsHistory,
  ...customerInformation,
  ...searchCheckResultDetailList,
  ...getCheckResultDetail,
  ...getCreditManagementDownload,
  ...uploadCreditManagementsFile,
  ...searchCreditManagements,
  ...getCheckDecisions,
  ...createCheckDecision,
  ...updateCheckDecision,
  ...deleteCheckDecision,
  ...dashboard,
  ...auth,
  ...downloadCreditManagementFiles,
  ...getCreditManagementExecuteStatus,
  ...creditExecution,
  ...newspaperConditionSettings,
  ...bulkRegisterCheckDecision,
  ...bulkUpdateClickAt,
  ...getCheckResultDetailCheckCondition,
  ...checkNoticeSetting,
  ...apiSetting,
];
