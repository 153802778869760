import { axiosInstance } from '@/shared/api/axios';
import {
  ApiSettingColumnGetRequestType,
  ApiSettingColumnGetResponseType,
  ApiSettingColumnPostRequestType,
  ApiSettingColumnPostResponseType,
  ApiSettingConnectTestResponseType,
  ApiSettingFunctionsGetResponseType,
  ApiSettingGetResponseType,
  CdataApiSettingInfoPostRequestType,
  ApiSettingTableGetRequestType,
  ApiSettingTableGetResponseType,
  ApiSettingTablePostRequestType,
  ApiSettingTablePostResponseType,
  groupIdType,
  ApiSettingColumnDeleteRequestType,
  ApiSettingColumnDeleteResponseType,
  ApiSettingUpdateResponseType,
} from '@/shared/types/api/api.ApiSetting';

export const getList = async ({ groupId }: groupIdType): Promise<ApiSettingGetResponseType> => {
  const { data } = await axiosInstance.get<ApiSettingGetResponseType>(
    `/group/${groupId}/cdata/setting/auth`,
  );
  return data;
};

export const connectTest = async ({
  groupId,
  kind,
  authUserId,
  authPassword,
  authToken,
  workspace,
  keyJson,
}: CdataApiSettingInfoPostRequestType): Promise<ApiSettingConnectTestResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingConnectTestResponseType>(
    `/group/${groupId}/cdata/setting/connect`,
    {
      kind,
      authUserId,
      authPassword,
      authToken,
      workspace,
      keyJson,
    },
  );
  return data;
};

export const createAuth = async ({
  groupId,
  kind,
  authUserId,
  authPassword,
  authToken,
  workspace,
  keyJson,
}: CdataApiSettingInfoPostRequestType): Promise<ApiSettingConnectTestResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingConnectTestResponseType>(
    `/group/${groupId}/cdata/setting/service`,
    {
      kind,
      authUserId,
      authPassword,
      authToken,
      workspace,
      keyJson,
    },
  );
  return data;
};
export const updateAuth = async ({
  apiAuthInformationId,
  kind,
  authUserId,
  authPassword,
  authToken,
  workspace,
  groupId,
}: CdataApiSettingInfoPostRequestType): Promise<ApiSettingUpdateResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingUpdateResponseType>(
    `/group/${groupId}/cdata/setting/service/${apiAuthInformationId}`,
    {
      kind,
      authUserId,
      authPassword,
      authToken,
      workspace,
    },
  );
  return data;
};

export const getFunctions = async ({
  groupId,
}: groupIdType): Promise<ApiSettingFunctionsGetResponseType> => {
  const { data } = await axiosInstance.get<ApiSettingFunctionsGetResponseType>(
    `/group/${groupId}/cdata/setting/functions`,
  );
  return data;
};

export const getTables = async ({
  groupId,
  kind,
  authUserId,
  authPassword,
  authToken,
  workspace,
  keyJson,
}: ApiSettingTableGetRequestType): Promise<ApiSettingTableGetResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingTableGetResponseType>(
    `/group/${groupId}/cdata/setting/get-table`,
    {
      kind,
      authUserId,
      authPassword,
      authToken,
      workspace,
      keyJson,
    },
  );
  return data;
};

export const createTable = async ({
  groupId,
  kind,
  cdataFunctionId,
  tableName,
  displayName,
}: ApiSettingTablePostRequestType): Promise<ApiSettingTablePostResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingTablePostResponseType>(
    `/group/${groupId}/cdata/setting/table`,
    {
      kind,
      cdataFunctionId,
      tableName,
      displayName,
    },
  );
  return data;
};

export const getColumns = async ({
  groupId,
  auth,
  cdataFunctionId,
  tableName,
}: ApiSettingColumnGetRequestType): Promise<ApiSettingColumnGetResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingColumnGetResponseType>(
    `/group/${groupId}/cdata/setting/get-column`,
    {
      auth,
      cdataFunctionId,
      tableName,
    },
  );
  return data;
};

export const createColumn = async ({
  groupId,
  columns,
}: ApiSettingColumnPostRequestType): Promise<ApiSettingColumnPostResponseType> => {
  const { data } = await axiosInstance.post<ApiSettingColumnPostResponseType>(
    `/group/${groupId}/cdata/setting/column`,
    {
      columns,
    },
  );
  return data;
};

export const deleteApiSetting = async ({
  groupId,
  apiAuthInformationId,
}: ApiSettingColumnDeleteRequestType) => {
  const { data } = await axiosInstance.post<ApiSettingColumnDeleteResponseType>(
    `/group/${groupId}/cdata/setting/delete`,
    {
      apiAuthInformationId,
    },
  );
  return data;
};
