import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: ${Style.color.topHeaderBG};
  border-bottom: 1px solid ${Style.color.borderLine};
  padding-left: 20px;
  padding-right: 20px;
`;

export const TitleStyle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  & svg:first-of-type {
    margin-right: 4px;
  }
`;

export const NameStyle = styled.div`
  font-weight: bold;
`;
