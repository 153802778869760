import { checkBoxOption } from '@/shared/constants/checkbox';
import { code, codeConverter } from '@/shared/constants/code';
import { file } from '@/shared/constants/file';
import { localstorage } from '@/shared/constants/localstorage';
import { queryParameter } from '@/shared/constants/route';
import { selectOption } from '@/shared/constants/selectOption';

export const constants = {
  localstorage,
  code,
  codeConverter,
  selectOption,
  checkBoxOption,
  file,
  queryParameter,
};
