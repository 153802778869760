import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { GroupsGetResponseType } from '@/shared/types/api/api.groups';
import {
  ApiSettingColumnGetResponseType,
  ApiSettingColumnPostRequestType,
  ApiSettingColumnPostResponseType,
  ApiSettingConnectTestResponseType,
  ApiSettingFunctionsGetResponseType,
  ApiSettingGetResponseType,
  ApiSettingTableGetResponseType,
  ApiSettingTablePostResponseType,
  ApiSettingUpdateResponseType,
  CdataApiSettingInfoPostRequestType,
} from '@/shared/types/api/api.ApiSetting';
import { faker } from '@faker-js/faker';
import { ApiAuthInformationGetResponseType } from '@/shared/types/api/api.apiAuthInformation';

export const apiSetting = [
  rest.get<ApiAuthInformationGetResponseType>(
    `${config.baseURL}/api-auth-information`,
    async (req, res, ctx) => {
      const kind = req.url.searchParams.get('kind');
      let result: ApiAuthInformationGetResponseType | null = {
        apiAuthInformationId: 123,
        kind: 'SALESFORCE',
        authUserId: '123',
        authPassword: 'password',
        authToken: 'authToken',
      };
      if (kind === 'KINTONE') {
        result = null;
      }

      return res(ctx.delay(delayTime), ctx.status(200), ctx.json(result));
    },
  ),
  rest.get<ApiSettingGetResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/auth`,
    async (req, res, ctx) => {
      const result: ApiSettingGetResponseType = {
        apiAuthInformationList: [
          {
            apiAuthInformationId: 1,
            kind: 'SALESFORCE',
            authUserId: 123,
          },
          {
            apiAuthInformationId: 2,
            kind: 'KINTONE',
          },
          {
            apiAuthInformationId: 3,
            kind: 'GOOGLE_SPREAD_SHEET',
          },
        ],
      };
      return res(ctx.delay(delayTime), ctx.status(200), ctx.json(result));
    },
  ),
  rest.post<CdataApiSettingInfoPostRequestType>(
    `${config.baseURL}/group/:groupId/cdata/setting/service`,
    async (req, res, ctx) => {
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          status: 200,
        }),
      );
    },
  ),
  rest.post<ApiSettingConnectTestResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/connect`,
    async (req, res, ctx) => {
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          status: 200,
        }),
      );
    },
  ),
  rest.get<ApiSettingFunctionsGetResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/functions`,
    async (req, res, ctx) => {
      const result: ApiSettingFunctionsGetResponseType = {
        cdataFunctions: [
          {
            id: 1,
            name: 'customer',
            displayName: '取引先データテーブル',
          },
          {
            id: 2,
            name: 'customer_detail',
            displayName: '詳細データテーブル',
          },
        ],
      };
      return res(ctx.delay(delayTime), ctx.status(200), ctx.json(result));
    },
  ),
  rest.post<ApiSettingTableGetResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/get-table`,
    async (req, res, ctx) => {
      const result: ApiSettingTableGetResponseType = {
        tableList: [
          {
            tableName: 'tableName1',
            displayName: 'displayName1',
          },
          {
            tableName: 'tableName2',
            displayName: 'displayName2',
          },
          {
            tableName: 'tableName2',
            displayName: 'displayName2',
          },
        ],
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.post<ApiSettingTablePostResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/table`,
    async (req, res, ctx) => {
      const result: ApiSettingTablePostResponseType = {
        cdataExternalTableSettingId: faker.datatype.number({ min: 1, max: 2 }),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.post<ApiSettingColumnGetResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/get-column`,
    async (req, res, ctx) => {
      const result: ApiSettingColumnGetResponseType = {
        cdataFunctionsItemsList: [
          {
            cdataFunctionsItemsId: 1,
            cdataItemName: 'cdataItemName',
            required: true,
            dataType: 'string',
          },
          {
            cdataFunctionsItemsId: 2,
            cdataItemName: 'cdataItemName2',
            required: true,
            dataType: 'string',
          },
        ],
        externalColumnList: [
          {
            columnName: 'columnName',
            dataTypeName: 'dataTypeName',
            description: 'テキスト',
          },
          {
            columnName: 'columnName2',
            dataTypeName: 'dataTypeName2',
            description: 'テキスト',
          },
        ],
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.post<ApiSettingColumnPostResponseType>(
    `${config.baseURL}/group/:groupId/cdata/setting/column`,
    async (req, res, ctx) => {
      const result: ApiSettingColumnPostResponseType = {
        cDataItemSettingIds: [1, 2, 3],
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.post<CdataApiSettingInfoPostRequestType>(
    `${config.baseURL}/group/:groupId/cdata/setting/service/:apiAuthInformationId`,
    async (req, res, ctx) => {
      const result: ApiSettingUpdateResponseType = {
        apiAuthInformationId: 1,
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
