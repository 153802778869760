import { atom } from 'recoil';

import { FunctionalRestrictionDialogType } from '@/shared/types/common.type';

export const functionalRestrictionDialogState = atom<FunctionalRestrictionDialogType>({
  key: 'functionalRestrictionDialog',
  default: {
    open: false,
  },
});
