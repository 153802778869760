export const file = {
  templateUploadFileUrl: {
    compliance:
      'https://compliance-check-public-open.s3.ap-northeast-1.amazonaws.com/%E3%82%B3%E3%83%B3%E3%83%95%E3%82%9A%E3%83%A9%E3%82%A4%E3%82%A2%E3%83%B3%E3%82%B9%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%83%86%E3%83%B3%E3%83%95%E3%82%9A%E3%83%AC%E3%83%BC%E3%83%88.xlsx',
    reference:
      'https://compliance-check-public-open.s3.ap-northeast-1.amazonaws.com/%E3%83%AA%E3%83%95%E3%82%A1%E3%83%AC%E3%83%B3%E3%82%B9%E3%83%81%E3%82%A7%E3%83%83%E3%82%AF%E3%83%86%E3%83%B3%E3%83%95%E3%82%9A%E3%83%AC%E3%83%BC%E3%83%88.xlsx',
    cautionlist:
      'https://compliance-check-public-open.s3.ap-northeast-1.amazonaws.com/%E6%B3%A8%E6%84%8F%E5%85%88%E3%83%AA%E3%82%B9%E3%83%88%E7%99%BB%E9%8C%B2%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88.xlsx',
    blacklist:
      'https://compliance-check-public-open.s3.ap-northeast-1.amazonaws.com/%E3%83%96%E3%83%A9%E3%83%83%E3%82%AF%E3%83%AA%E3%82%B9%E3%83%88%E7%99%BB%E9%8C%B2%E3%83%86%E3%83%B3%E3%83%97%E3%83%AC%E3%83%BC%E3%83%88.xlsx',
  },
};
