import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const NaviFooterContainerStyle = styled.div`
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 200px;
  padding: 12px 12px;
  box-sizing: border-box;
  font-size: 1.4rem;
  color: ${Style.color.black};
`;

export const NaviFooterItemStyle = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 30px;
`;

export const NaviFooterItemNameStyle = styled.span`
  margin-left: 5px;
`;
