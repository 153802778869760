import React, { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { MessageBodyStyle, MessageLabelStyle } from '../MessageDialog/css';

import { authInformationState } from '@/recoil/atoms/authInformationState';
import { blacklistRestrictionDialogSelector } from '@/recoil/selectors/blacklistRestrictionDialogSelector';
import Button from '@/shared/components/Button';
import ModalTemplate from '@/shared/components/DialogTemplate';
import { authCode } from '@/shared/constants/code';

const BlacklistRestrictionDialog: React.FC = () => {
  const { handleClose, open } = useRecoilValue(blacklistRestrictionDialogSelector);
  const setDialogDialogState = useSetRecoilState(blacklistRestrictionDialogSelector);

  const onClose = useCallback(() => {
    setDialogDialogState({ open: false });
    handleClose && handleClose();
  }, [handleClose, setDialogDialogState]);

  const { authority } = useRecoilValue(authInformationState);
  const height = 50;
  const width = 300;
  return (
    <ModalTemplate
      open={open}
      handleClose={onClose}
      width={900}
      title="ブラックリスト機能"
      contents={
        <MessageBodyStyle>
          <MessageLabelStyle>
            ご利用にはカスタマーサクセスへのご連絡が必要となります。
          </MessageLabelStyle>
        </MessageBodyStyle>
      }
      footer={
        <div>
          {[authCode.authority.OWNER].includes(authority || 0) && (
            <Button
              text="ブラックリスト機能 詳細ページ"
              variant="outlined"
              height={height}
              width={width}
              onClick={() => window.open(import.meta.env.VITE_BLACKLIST_URL)}
            />
          )}
          {[authCode.authority.OWNER].includes(authority || 0) && (
            <Button
              text="お問い合わせ"
              variant="outlined"
              marginLeft={15}
              height={height}
              width={width}
              onClick={() => window.open(import.meta.env.VITE_CONTACT_URL)}
            />
          )}
          {[
            authCode.authority.ALL,
            authCode.authority.EXECUTE,
            authCode.authority.READONLY,
          ].includes(authority || 0) && (
            <Button
              text="お問い合わせ"
              variant="outlined"
              marginLeft={15}
              height={height}
              width={width}
              onClick={() => window.open(import.meta.env.VITE_CONTACT_URL)}
            />
          )}
        </div>
      }
    />
  );
};

export default BlacklistRestrictionDialog;
