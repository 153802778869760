import jwtDecode from 'jwt-decode';
import { DefaultValue, selector } from 'recoil';

import { authInformationState, tokenState } from '@/recoil/atoms/authInformationState';
import { authCode } from '@/shared/constants/code';
import { AuthPostResponseType } from '@/shared/types/api/api.auth';
import { AuthType } from '@/shared/types/common.type';

export const authInformationSelector = selector<Partial<AuthType>>({
  key: 'authInformationSelector',
  get: ({ get }) => get(authInformationState),
});

export const isNewspaperState = selector<boolean>({
  key: 'isNewspaperState',
  get: ({ get }) =>
    [authCode.contractType.NET_PAPER_CREDIT].includes(
      get(authInformationState).contract?.type || -1,
    ),
});

export const isCreditState = selector<boolean>({
  key: 'isCreditState',
  get: ({ get }) =>
    [authCode.contractType.NET_CREDIT, authCode.contractType.NET_PAPER_CREDIT].includes(
      get(authInformationState).contract?.type || -1,
    ),
});

export const tokenSelector = selector<AuthPostResponseType>({
  key: 'tokenSelector',
  get: ({ get }) => get(tokenState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // NOTE: DefaultValue型のときはresetから呼ばれたとき
      reset(tokenState);
      reset(authInformationState);
      return;
    }

    const decodedAccessToken: AuthType = jwtDecode(newValue.access_token);
    set(tokenState, newValue);
    set(authInformationState, decodedAccessToken);
  },
});
