import React, { useMemo } from 'react';

import { useRecoilState } from 'recoil';

import loading00 from '@/assets/loading/logo-00.png';
import loading01 from '@/assets/loading/logo-01.png';
import loading10 from '@/assets/loading/logo-10.png';
import loading11 from '@/assets/loading/logo-11.png';
import {
  LoadingStyle,
  LoadingDivForSuspenseStyle,
  LoadingMessageStyle,
  LogoStyle,
  LogosBlockStyle,
} from '@/components/Loading/css';
import { loadingState } from '@/recoil/atoms/loadingState';

interface PropsType {
  isSuspense?: boolean;
}

const Loading: React.FC<PropsType> = ({ isSuspense }) => {
  const [isLoading] = useRecoilState(loadingState);

  const logo = useMemo(
    () => (
      <>
        <LogosBlockStyle>
          <LogoStyle src={loading00} type="00" />
          <LogoStyle src={loading01} type="01" />
          <LogoStyle src={loading10} type="10" />
          <LogoStyle src={loading11} type="11" />
        </LogosBlockStyle>
        <LoadingMessageStyle>
          <h2>読み込み中です...</h2>
        </LoadingMessageStyle>
      </>
    ),
    [],
  );

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isSuspense ? (
        <LoadingDivForSuspenseStyle>{logo}</LoadingDivForSuspenseStyle>
      ) : (
        isLoading && <LoadingStyle>{logo}</LoadingStyle>
      )}
    </>
  );
};

export default Loading;
