import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  FileManagementGetResponseType,
  FileManagementFileDownloadPostResponseType,
  FileManagementGetRequestType,
  FileManagementFileDownloadPostRequestType,
} from '@/shared/types/api/api.fileManagement';

export const getFileManagementFiles = async ({
  groupId,
  pageSize,
  pageIndex,
}: FileManagementGetRequestType): Promise<TableResultType<FileManagementGetResponseType[]>> => {
  const { data } = await axiosInstance.get<TableResultType<FileManagementGetResponseType[]>>(
    `/group/${groupId}/file-management`,
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
      },
    },
  );
  return data;
};

export const downloadFilesForFileManagement = async ({
  groupId,
  fileManagementIdList,
}: FileManagementFileDownloadPostRequestType) => {
  const { data } = await axiosInstance.post<FileManagementFileDownloadPostResponseType>(
    `/group/${groupId}/file-management/download`,
    {
      fileManagementIdList,
    },
  );
  return data;
};
