import styled from '@emotion/styled';

import Styles from '@/shared/styles';

type BodyType = {
  width?: number;
};

type FontType = {
  fontSize?: string;
  fontWeight?: string;
};

export const Body = styled.div<BodyType & FontType>`
  width: ${({ width }) => `${width || 1200}px`};
  font-size: ${({ fontSize }) => fontSize || Styles.default.fontSize};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  padding: 35px;
  font-family: ${Styles.default.fontFamily};
`;

export const Title = styled.div<FontType>`
  height: 45px;
  font-size: ${({ fontSize }) => fontSize || '2.5rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  display: flex;
  justify-content: space-between;
`;

export const Contents = styled.div`
  height: calc(100% - 100px);
  border-top: solid 2px ${Styles.color.grey};
`;

export const Footer = styled.div<FontType>`
  height: 45px;
  font-size: ${({ fontSize }) => fontSize || '2rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
