import { atom } from 'recoil';

import { MessageDialogType } from '@/shared/types/common.type';

export const messageDialogState = atom<MessageDialogType>({
  key: 'messageDialog',
  default: {
    open: false,
  },
});
