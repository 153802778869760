import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const InstructorVideoStyle = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 17px;
  color: ${Style.color.blue};
  border: 2px solid ${Style.color.blue};
  width: 180px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  & div:first-of-type {
    margin-right: 5px;
  }
`;

export const PlayVideoStyle = styled.video`
  width: 800px;
`;
