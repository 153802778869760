/* eslint-disable no-control-regex,no-misleading-character-class */
export const unmanagedSpaces =
  '\u001c-\u001f\u11a3-\u11a7\u180e\u200b-\u200f\u2060\u3164\u034f\u202a-\u202e\u2061-\u2063';
export const allSpaceRegexp = RegExp(`[\\s${unmanagedSpaces}]+`, 'g');
export const unmanagedRegexp = RegExp(`[${unmanagedSpaces}]+`, 'g');

export const replaceUnmanagedRegexp = (value: string) => value.replace(unmanagedRegexp, '');

export const replaceAllUnmanagedRegexp = <T extends object>(data: T) => {
  const convert = { ...data };
  (Object.keys(data) as (keyof T)[]).forEach((key) => {
    const value = data[key];
    if (typeof value === 'string') {
      convert[key] = replaceUnmanagedRegexp(value) as any;
    }
  });
  return convert;
};

export const replaceAllSpaceToHalfSpace = (value: string) =>
  value.replace(/[\t\u3000]+/g, ' ').trim();
