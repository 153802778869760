import { axiosInstance } from '@/shared/api/axios';
import {
  NewspaperConditionGetRequestType,
  NewspaperConditionGetResponseType,
  NewspaperConditionPutRequestType,
  NewspaperConditionPutResponseType,
} from '@/shared/types/api/api.newspaperCondition';

export const getNewspaperConditionSettings = async ({
  groupId,
}: NewspaperConditionGetRequestType): Promise<NewspaperConditionGetResponseType> => {
  const { data } = await axiosInstance.get<NewspaperConditionGetResponseType>(
    `/group/${groupId}/newspaper-condition`,
  );
  return data;
};

export const updateNewspaperConditionSettings = async ({
  groupId,
  isSearchActivated,
  isPeriodActivated,
  searchStartDate,
  searchEndDate,
  operatorAnd,
  operatorNot,
}: NewspaperConditionPutRequestType): Promise<NewspaperConditionPutResponseType> => {
  const { data } = await axiosInstance.put<NewspaperConditionPutResponseType>(
    `/group/${groupId}/newspaper-condition`,
    {
      isSearchActivated,
      isPeriodActivated,
      searchStartDate,
      searchEndDate,
      operatorAnd,
      operatorNot,
    },
  );

  return data;
};
