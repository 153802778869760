import React from 'react';
import { Link } from 'react-router-dom';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useRecoilValue } from 'recoil';

import {
  NaviFooterContainerStyle,
  NaviFooterItemStyle,
  NaviFooterItemNameStyle,
} from '@/components/Navigation/NavigationFooter/css';
import { authInformationState } from '@/recoil/atoms/authInformationState';
import { isNewspaperState } from '@/recoil/selectors/authInformationSelector';
import { authCode } from '@/shared/constants/code';
import { checkServiceType } from '@/shared/utils/serviceType';

const NaviBarFooter: React.FC = () => {
  const { authority, useManageMenuFlg } = useRecoilValue(authInformationState);
  const isNewspaper = useRecoilValue(isNewspaperState);
  const isCompliance = checkServiceType();
  return (
    <NaviFooterContainerStyle>
      {isNewspaper && (
        <Link
          key="news-paper-price"
          to="/news-paper-price"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <NaviFooterItemStyle>
            <HelpOutlineOutlinedIcon style={{ cursor: 'pointer' }} fontSize="large" />
            <NaviFooterItemNameStyle>新聞記事閲覧料金</NaviFooterItemNameStyle>
          </NaviFooterItemStyle>
        </Link>
      )}
      {useManageMenuFlg && (
        <a
          href={import.meta.env.VITE_PORTAL_GROUP_LIST_URL}
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <NaviFooterItemStyle>
            <GroupsOutlinedIcon style={{ cursor: 'pointer' }} fontSize="large" />
            <NaviFooterItemNameStyle>管理メニュー</NaviFooterItemNameStyle>
          </NaviFooterItemStyle>
        </a>
      )}
      {isCompliance ? (
        <a
          href={import.meta.env.VITE_FAQ_URL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <NaviFooterItemStyle>
            <HelpOutlineOutlinedIcon style={{ cursor: 'pointer' }} fontSize="large" />
            <NaviFooterItemNameStyle>ご不明点はこちら</NaviFooterItemNameStyle>
          </NaviFooterItemStyle>
        </a>
      ) : (
        <a
          href={import.meta.env.VITE_FAQ_REFERENCE_URL}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <NaviFooterItemStyle>
            <HelpOutlineOutlinedIcon style={{ cursor: 'pointer' }} fontSize="large" />
            <NaviFooterItemNameStyle>ヘルプ</NaviFooterItemNameStyle>
          </NaviFooterItemStyle>
        </a>
      )}
      <a
        href={import.meta.env.VITE_MY_PAGE_URL}
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        <NaviFooterItemStyle>
          <PermIdentityIcon style={{ cursor: 'pointer' }} fontSize="large" />
          <NaviFooterItemNameStyle>アカウント設定</NaviFooterItemNameStyle>
        </NaviFooterItemStyle>
      </a>
      {[authCode.authority.OWNER].includes(authority || -1) && (
        <a
          href={import.meta.env.VITE_PORTAL_CONTRACT_URL}
          rel="noopener noreferrer"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          <NaviFooterItemStyle>
            <CheckCircleOutlinedIcon style={{ cursor: 'pointer' }} fontSize="large" />
            <NaviFooterItemNameStyle>契約管理</NaviFooterItemNameStyle>
          </NaviFooterItemStyle>
        </a>
      )}
    </NaviFooterContainerStyle>
  );
};

export default NaviBarFooter;
