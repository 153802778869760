import { keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';

import Styles from '@/shared/styles';

export const LoadingDivForSuspenseStyle = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${Styles.color.bodyBG};
  z-index: 3000;
`;

export const LoadingStyle = styled.div`
  position: fixed !important;
  font-family: ${Styles.default.fontFamily};
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.6);
  z-index: 3000;
`;

const loading00 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, 80px);
  }
  50% {
    transform: translate(80px, 80px);
  }
  75% {
    transform: translate(80px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const loading01 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(80px, 0);
  }
  50% {
    transform: translate(80px, -80px);
  }
  75% {
    transform: translate(0, -80px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const loading10 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-80px, 0);
  }
  50% {
    transform: translate(-80px, 80px);
  }
  75% {
    transform: translate(0, 80px);
  }
  100% {
    transform: translate(0, 0);
  }
`;

const loading11 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(0, -80px);
  }
  50% {
    transform: translate(-80px, -80px);
  }
  75% {
    transform: translate(-80px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
`;

type LogoStyleType = {
  type: '00' | '01' | '10' | '11';
};

const LogoImageStyle = ({ type }: LogoStyleType) => {
  switch (type) {
    case '00':
      return css`
        position: absolute;
        top: 0;
        left: 0;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
        animation: ${loading00} 3s linear infinite;
      `;
    case '01':
      return css`
        position: absolute;
        top: 80px;
        left: 0;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
        animation: ${loading01} 3s linear infinite;
      `;
    case '10':
      return css`
        position: absolute;
        top: 0;
        left: 80px;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
        animation: ${loading10} 3s linear infinite;
      `;
    case '11':
      return css`
        position: absolute;
        top: 80px;
        left: 80px;
        filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.3));
        animation: ${loading11} 3s linear infinite;
      `;
    default:
      return css``;
  }
};

export const LogosBlockStyle = styled.div`
  position: relative;
  width: 144px;
  height: 144px;
`;

export const LogoStyle = styled.img<LogoStyleType>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  transition: 1s;
  ${LogoImageStyle}
`;

export const LoadingMessageStyle = styled.div`
  margin-top: 16px;
  background: white;
  padding: 8px 32px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgb(0 0 0 / 20%);
  h2 {
    font-size: 1.2rem;
    font-weight: 600;
  }
`;
