import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog } from '@mui/material';

import { Contents, Title, Body, Footer } from '@/shared/components/DialogTemplate/css';

export type DialogFunctionType = {
  open: boolean;
  handleClose: () => void;
  contents: JSX.Element;
};

export type ButtonCssType = {
  height: number;
  width: number;
};

interface PropsType {
  width?: number;
  fontSize?: string;
  fontWeight?: string;
  titleFontSize?: string;
  titleFontWeight?: string;
  footerFontSize?: string;
  footerFontWeight?: string;
  title: string | JSX.Element;
  contents: JSX.Element;
  footer?: JSX.Element;
}

const DialogTemplate: React.FC<PropsType & DialogFunctionType> = ({
  open,
  handleClose,
  width,
  fontSize,
  fontWeight,
  titleFontSize,
  titleFontWeight,
  footerFontSize,
  footerFontWeight,
  title,
  contents,
  footer,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={false}>
      <Box sx={{ position: 'relative' }}>
        <Body width={width} fontSize={titleFontSize} fontWeight={titleFontWeight}>
          <Title fontSize={fontSize} fontWeight={fontWeight}>
            <div style={{ width: '100%' }}>{title}</div>
            <CloseIcon fontSize="large" onClick={handleClose} style={{ cursor: 'pointer' }} />
          </Title>
          <Contents>{contents}</Contents>
          {footer && (
            <Footer fontSize={footerFontSize} fontWeight={footerFontWeight}>
              {footer}
            </Footer>
          )}
        </Body>
      </Box>
    </Dialog>
  );
};

export default DialogTemplate;
