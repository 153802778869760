import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  CheckResultDetailExecuteConditionGetResponseType,
  CheckResultDetailFileDownloadPostRequestType,
  CheckResultDetailFileDownloadPostResponseType,
  CheckResultDetailSearchResponseType,
  CheckResultDetailSearchRequestType,
  CheckResultDetailExecuteConditionGetRequestType,
  CheckResultDetailUpdateConfirmedPutRequestType,
  CheckResultDetailUpdateConfirmedPutResponseType,
  CheckResultDetailFileDownloadGetRequestType,
  CheckResultDetailFileDownloadGetResponseType,
} from '@/shared/types/api/api.checkResultDetail';

export const getCheckResultDetailCheckCondition = async ({
  groupId,
  checkExecutionId,
}: CheckResultDetailExecuteConditionGetRequestType): Promise<CheckResultDetailExecuteConditionGetResponseType> => {
  const { data } = await axiosInstance.get<CheckResultDetailExecuteConditionGetResponseType>(
    `/group/${groupId}/check-execution/${checkExecutionId}`,
  );
  return data;
};

export const searchCheckResultDetailList = async ({
  groupId,
  checkExecutionId,
  pageIndex,
  pageSize,
  sortOrder,
  sortTarget,
  condition,
}: CheckResultDetailSearchRequestType): Promise<
  TableResultType<CheckResultDetailSearchResponseType[]>
> => {
  const { data } = await axiosInstance.post<TableResultType<CheckResultDetailSearchResponseType[]>>(
    `/group/${groupId}/check-result/${checkExecutionId}/detail/search`,
    {
      ...condition,
    },
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
        sortTarget,
        sortOrder,
      },
    },
  );
  return data;
};

export const updateConfirmed = async ({
  groupId,
  checkExecutionResultId,
  isConfirmed,
}: CheckResultDetailUpdateConfirmedPutRequestType): Promise<CheckResultDetailUpdateConfirmedPutResponseType> => {
  const { data } = await axiosInstance.put<CheckResultDetailUpdateConfirmedPutResponseType>(
    `/group/${groupId}/check-execution-result/${checkExecutionResultId}`,
    {
      isConfirmed,
    },
  );
  return data;
};

export const downloadCheckResultDetailFiles = async ({
  groupId,
  fileTypes,
  checkExecutionResultIds,
}: CheckResultDetailFileDownloadPostRequestType): Promise<
  CheckResultDetailFileDownloadPostResponseType[]
> => {
  const { data } = await axiosInstance.post<CheckResultDetailFileDownloadPostResponseType[]>(
    `/group/${groupId}/check-execution-result/file`,
    {
      fileTypes,
      checkExecutionResultIds,
    },
  );
  return data;
};
export const pdfDownloadCheckResultsDetail = async ({
  groupId,
  checkExecutionResultId,
}: CheckResultDetailFileDownloadGetRequestType): Promise<CheckResultDetailFileDownloadGetResponseType> => {
  const { data } = await axiosInstance.get<CheckResultDetailFileDownloadGetResponseType>(
    `/group/${groupId}/check-execution-result/${checkExecutionResultId}/file-download`,
  );
  return data;
};
