import React, { useMemo, Suspense, useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import Footer from '@/components/Footer';
import Loading from '@/components/Loading';
import NaviBar from '@/components/Navigation';
import ScrollTopButton from '@/components/ScrollTopButton';
import TopHeader from '@/components/TopHeader';
import { authInformationState, tokenState } from '@/recoil/atoms/authInformationState';
import { currentRouteState } from '@/recoil/atoms/currentRouteState';
import { currentRouteSelector } from '@/recoil/selectors/currentRouteSeletcor';
import { searchWordSelector } from '@/recoil/selectors/searchwordSelector';
import { LayoutFull, LayoutBody, LayoutContents } from '@/screens/Routing/css';
import RouteWithSubRoutes from '@/shared/components/RouteWithSubRoutes';
import { constants } from '@/shared/constants';
import { authCode } from '@/shared/constants/code';
import { useMatchedRoute } from '@/shared/hooks/useMatchedRoute';
import routes, { paths } from '@/shared/routes';
import { RoutesType } from '@/shared/types/route.type';
import { jsonParse } from '@/shared/utils/json';

const Routing: React.FC = () => {
  const layoutContentsRef = useRef<HTMLDivElement>(null);
  const tokenValue = useRecoilValue(tokenState);
  const auth = useRecoilValue(authInformationState);
  const { contract, hasOwnerApiToken } = useRecoilValue(authInformationState);
  const navigate = useNavigate();

  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>(paths.customers);
  const setSearchWordRecoilState = useSetRecoilState(searchWordSelector);
  const targetRoutes = useMemo(
    () =>
      routes.filter(
        (r) =>
          ((r.display ? r.display(auth) : true) &&
            !r.deIncludeAuthority?.includes(auth.authority || 0)) ??
          true,
      ),
    [auth],
  );
  const routeExtended = useMemo(() => {
    const defaultRoute = <Route key="/" path="*" element={<Navigate to={redirectUrl} />} />;
    // フォルダ管理：従量課金でapiトークンがないorフリーミアムプランの場合は制限
    return targetRoutes
      .filter(
        (filterRoute) =>
          !(
            ((contract?.unitType === authCode.contractUnitType.PAY_PER_USE && !hasOwnerApiToken) ||
              contract?.paymentType === authCode.paymentType.FREEMIUM) &&
            filterRoute.name === 'フォルダ管理'
          ) ?? true,
      )
      .map((route) => RouteWithSubRoutes(route))
      .concat([defaultRoute]);
  }, [targetRoutes, redirectUrl, contract?.unitType, contract?.paymentType, hasOwnerApiToken]);

  const currentRoute = useRecoilValue<RoutesType>(currentRouteSelector);
  const setCurrentRouteState = useSetRecoilState(currentRouteState);
  const matchedRoute = useMatchedRoute();
  useEffect(() => {
    setCurrentRouteState(matchedRoute || currentRoute);
  }, [setCurrentRouteState, matchedRoute, currentRoute]);

  useEffect(() => {
    const currentPath = localStorage.getItem(constants.localstorage().currentPath);
    if (currentPath && !!tokenValue.access_token && !firstLoaded) {
      setFirstLoaded(true);
      localStorage.removeItem(constants.localstorage().currentPath);
      navigate(currentPath);
    }
  }, [navigate, tokenValue, firstLoaded]);
  useEffect(() => {
    const state = jsonParse(localStorage.getItem(constants.localstorage().authorizeState));
    if (state && state.checkResultSearch) {
      // NOTE: Pro-sign 連携
      setSearchWordRecoilState(state.checkResultSearch as string);
      setRedirectUrl(paths.checkResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutFull>
      <NaviBar routes={targetRoutes} />
      <LayoutBody>
        <Suspense fallback={<Loading isSuspense />}>
          <TopHeader />
          <LayoutContents ref={layoutContentsRef} margin={20} hasFooter={currentRoute.hasFooter}>
            <Routes>{routeExtended}</Routes>
            <ScrollTopButton showBelow={250} targetRef={layoutContentsRef} />
          </LayoutContents>
        </Suspense>
        <Footer />
      </LayoutBody>
    </LayoutFull>
  );
};

export default Routing;
