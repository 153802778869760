import { DefaultValue, selector } from 'recoil';

import { searchWordState } from '@/recoil/atoms/searchwordState';
import { replaceUnmanagedRegexp } from '@/shared/utils/space';

export const searchWordSelector = selector<string>({
  key: 'searchWordSelector',
  get: ({ get }) => get(searchWordState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(searchWordState);
    }
    if (typeof newValue === 'string') set(searchWordState, replaceUnmanagedRegexp(newValue));
  },
});
