import styled from '@emotion/styled';

import Style from '@/shared/styles';

export const NaviStyle = styled.div`
  background-color: ${Style.color.navigationBG};
`;

export const LogoStyle = styled.a`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-bottom: 1px solid ${Style.color.borderLine};
  &:hover {
    opacity: ${Style.default.hover.opacity};
  }
`;

export const MenuListStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  font-size: 1.4rem;
`;

type SelectedProps = {
  selected: boolean;
};

export const MenuItemStyle = styled.div<SelectedProps>`
  height: 57px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-bottom: 1px solid ${Style.color.borderLine};
  color: ${Style.color.black};
  background-color: ${({ selected }) => (selected ? Style.color.whiteGrey : Style.color.white)};
  &:hover {
    opacity: ${Style.default.hover.opacity};
  }
`;

export const MenuNameStyle = styled.span`
  margin-left: 5px;
`;
