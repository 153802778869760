import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  CheckResultGetResponseType,
  CheckResultHistoryGetResponseType,
  CheckResultGetRequestType,
  CheckResultHistoryGetRequestType,
  CheckResultUpdateClickAtPutRequestType,
  CheckResultUpdateClickAtPutResponseType,
  CheckResultFileDownloadPostRequestType,
  CheckResultFileDownloadPostResponseType,
  CheckResultBulkUpdateClickAtPutRequestType,
  CheckResultBulkUpdateClickAtPutResponseType,
  CheckResultBulkOutputExcelPostRequestType,
  CheckResultBulkOutputExcelPostResponseType,
} from '@/shared/types/api/api.checkResult';
import { replaceAllSpaceToHalfSpace } from '@/shared/utils/space';

export const searchCheckResults = async ({
  groupId,
  folderId,
  customerName,
  pageSize,
  pageIndex,
  sortTarget,
  sortOrder,
  condition,
}: CheckResultGetRequestType): Promise<TableResultType<CheckResultGetResponseType[]>> => {
  const { data } = await axiosInstance.post<TableResultType<CheckResultGetResponseType[]>>(
    `/group/${groupId}/check-result/search`,
    {
      ...condition,
      customerNames: customerName
        ? replaceAllSpaceToHalfSpace(customerName)
            .split(' ')
            .filter((d) => d !== '')
        : [],
    },
    {
      params: {
        folderId,
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
        sortTarget,
        sortOrder,
      },
    },
  );
  return data;
};

export const searchCheckResultsHistory = async ({
  groupId,
  customerId,
  pageSize,
  pageIndex,
}: CheckResultHistoryGetRequestType): Promise<
  TableResultType<CheckResultHistoryGetResponseType[]>
> => {
  const { data } = await axiosInstance.get<TableResultType<CheckResultHistoryGetResponseType[]>>(
    `/group/${groupId}/customer/${customerId}/check-result/history`,
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
      },
    },
  );
  return data;
};

export const updateClickAt = async ({
  groupId,
  checkExecutionId,
}: CheckResultUpdateClickAtPutRequestType): // groupId: number,
Promise<CheckResultUpdateClickAtPutResponseType> => {
  const { data } = await axiosInstance.put<CheckResultUpdateClickAtPutResponseType>(
    `/group/${groupId}/check-execution/${checkExecutionId}`,
  );
  return data;
};

export const downloadCheckResultFiles = async ({
  groupId,
  fileTypes,
  checkExecutionIds,
}: CheckResultFileDownloadPostRequestType): Promise<CheckResultFileDownloadPostResponseType[]> => {
  const { data } = await axiosInstance.post<CheckResultFileDownloadPostResponseType[]>(
    `/group/${groupId}/check-execution/file`,
    {
      fileTypes,
      checkExecutionIds,
    },
  );
  return data;
};

export const bulkUpdateClickAt = async ({
  groupId,
  checkExecutionIdList,
}: CheckResultBulkUpdateClickAtPutRequestType): Promise<CheckResultBulkUpdateClickAtPutResponseType> => {
  const { data } = await axiosInstance.put<CheckResultBulkUpdateClickAtPutResponseType>(
    `/group/${groupId}/check-execution/bulk-check`,
    {
      checkExecutionIdList,
    },
  );
  return data;
};

export const bulkOutputExcel = async ({
  groupId,
  folderId,
}: CheckResultBulkOutputExcelPostRequestType): Promise<CheckResultBulkOutputExcelPostResponseType> => {
  const { data } = await axiosInstance.post(`/group/${groupId}/check-result/bulk-output-excel`, {
    folderId,
  });
  return data;
};
