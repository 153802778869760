import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { convertToString, dateFormat } from '@/shared/utils/date';

export const folderList = [
  rest.get(`${config.baseURL}/group/:groupId/folder1`, (req, res, ctx) => {
    const limit: number = Number(req.url.searchParams.get('limit'));
    const offset: number = Number(req.url.searchParams.get('offset'));
    const searchWord: string | null = req.url.searchParams.get('folderName');

    const result = Array.from({ length: 315 }).map((i, index) => {
      return {
        folderId: index + 1,
        folderName:
          'コンプライアンスチェックテンプレート_' +
          convertToString(faker.date.recent(), dateFormat.yyyy_mm_dd),
        customerCount: index % 5 === 4 ? faker.random.numeric() : 0,
        registeredAt: faker.date.recent(),
      };
    });

    const filteredResult = searchWord
      ? result.filter(({ folderName }) => folderName.includes(searchWord))
      : result;

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json({
        total: filteredResult.length,
        offset,
        limit,
        rows: filteredResult.slice(offset, offset + limit),
      }),
    );
  }),
];
