import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { TableResultType } from '@/shared/types/api.type';
import {
  CheckDecisionBulkRegisterPostRequestType,
  CheckDecisionBulkRegisterPostResponseType,
  CheckDecisionDeleteResponseType,
  CheckDecisionGetResponseType,
  CheckDecisionPostResponseType,
  CheckDecisionUpdatePutResponseType,
} from '@/shared/types/api/api.checkDecision';

export const getCheckDecisions = [
  rest.get<TableResultType<CheckDecisionGetResponseType[]>>(
    `${config.baseURL}/group/:group_id/check-result/:check_execution_id/check-decision`,
    (req, res, ctx) => {
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const result: CheckDecisionGetResponseType[] = Array.from({ length: 10 }).map((i, index) => {
        return {
          checkDecisionId: index + 1,
          registeredAt: faker.date.recent(index),
          registeredUserId: index + 1,
          modifiedAt: faker.date.recent(index),
          userLastNameKanji: faker.name.lastName(),
          userFirstNameKanji: faker.name.firstName(),
          userLastNameKana: faker.name.lastName(),
          userFirstNameKana: faker.name.firstName(),
          checkDecisionChoice:
            index % 4 === 0
              ? 'APPROVE'
              : index % 4 === 1
              ? 'DISAPPROVE'
              : index % 4 === 2
              ? 'PENDING'
              : undefined,
          comment: index % 3 === 0 ? faker.word.noun() : undefined,
          hasFile: faker.datatype.boolean(),
          isEditable: faker.datatype.boolean(),
        };
      });
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: !offset && !limit ? result : result.slice(offset, offset + limit),
        }),
      );
    },
  ),
];

export const createCheckDecision = [
  rest.post<CheckDecisionPostResponseType>(
    `${config.baseURL}/group/:group_id/check-result/:check_execution_id/check-decision`,
    (req, res, ctx) => {
      const result: CheckDecisionPostResponseType = {
        checkDecisionId: faker.datatype.number(),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const updateCheckDecision = [
  rest.put<CheckDecisionUpdatePutResponseType>(
    `${config.baseURL}/group/:group_id/check-decision/:check_decision_id`,
    (req, res, ctx) => {
      const result: CheckDecisionUpdatePutResponseType = {
        checkDecisionId: faker.datatype.number(),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
export const deleteCheckDecision = [
  rest.post<CheckDecisionDeleteResponseType>(
    `${config.baseURL}/group/:group_id/check-decision/delete`,
    (req, res, ctx) => {
      const result: CheckDecisionDeleteResponseType = {
        checkDecisionId: faker.datatype.number(),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const bulkRegisterCheckDecision = [
  rest.post<CheckDecisionBulkRegisterPostResponseType>(
    `${config.baseURL}/group/:group_id/check-result/bulk-register`,
    (req, res, ctx) => {
      const body: CheckDecisionBulkRegisterPostRequestType = req.body;

      const result: CheckDecisionBulkRegisterPostResponseType = {
        checkExecutionIdList: body.checkExecutionIdList,
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
