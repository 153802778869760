import React, { useCallback, useState } from 'react';

import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';

import icon_movie from '@/assets/icon_movie.svg';
import { InstructorVideoStyle, PlayVideoStyle } from '@/components/InstructorVideo/css';
import { currentRouteSelector } from '@/recoil/selectors/currentRouteSeletcor';
import api from '@/shared/api';
import ModalTemplate from '@/shared/components/DialogTemplate';
import { functionType as usageFunctionType, functionUsage } from '@/shared/constants/functionUsage';
import { FunctionUsagePostRequestType } from '@/shared/types/api/api.functionUsage';
import { RoutesType } from '@/shared/types/route.type';

interface PropsType {
  marginLeft?: string;
}

const InstructorVideo: React.FC<PropsType> = ({ marginLeft }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const currentRoute = useRecoilValue<RoutesType>(currentRouteSelector);
  const { mutate } = useMutation({
    mutationFn: ({ actionType, page, functionType }: FunctionUsagePostRequestType) =>
      api.functionUsage.post({ actionType, page, functionType }),
  });
  const onClick = useCallback(() => {
    const target = functionUsage[currentRoute.path];
    mutate({
      page: target.page,
      actionType: target.actionType,
      functionType: usageFunctionType.manualVideoPlay,
    });
  }, [mutate, currentRoute.path]);
  const openCloseModal = useCallback(
    (open: boolean) => {
      if (open) onClick();
      setIsOpen(open);
    },
    [setIsOpen, onClick],
  );
  return (
    <>
      <InstructorVideoStyle style={{ marginLeft }} onClick={() => openCloseModal(true)}>
        <div>操作説明動画を見る</div>
        <img alt="logo" src={icon_movie} width="22" height="30" />
      </InstructorVideoStyle>
      <ModalTemplate
        open={isOpen}
        handleClose={() => openCloseModal(false)}
        width={800}
        title={currentRoute.instructionVideo?.title ?? ''}
        contents={
          <PlayVideoStyle controls autoPlay>
            <source src={currentRoute.instructionVideo?.videoUrl} type="video/mp4" />
            <track kind="captions" />
          </PlayVideoStyle>
        }
      />
    </>
  );
};

export default InstructorVideo;
