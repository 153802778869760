import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import {
  CheckCountGetResponseType,
  CheckCountHistoryGetResponseType,
  NoCheckCountGetResponseType,
  NoticeGetResponseType,
  ReducedTimeMinutesGetResponseType,
} from '@/shared/types/api/api.dashboard';
import { dateFormat, dateRangeForStringList } from '@/shared/utils/date';

export const dashboard = [
  rest.get(`${config.baseURL}/dashboard/notice`, (req, res, ctx) => {
    const result: NoticeGetResponseType[] = [
      { date: '2022/09/08', title: 'test4', url: 'www.google.com' },
      { date: '2022/09/08', title: 'test1', url: 'www.google.com' },
      { date: '2022/09/08', title: 'test2', url: 'www.google.com' },
    ];

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json(result),
    );
  }),
  rest.get(`${config.baseURL}/dashboard/check-count`, (req, res, ctx) => {
    const result: CheckCountGetResponseType = [
      {
        groupName: 'test1',
        google: 1,
        newspaper: 4,
        creditManagement: 5,
      },
      {
        groupName: 'test2',
        google: 2,
        newspaper: 4,
        creditManagement: 6,
      },
      {
        groupName: 'test3',
        google: 2,
        newspaper: 4,
        creditManagement: 7,
      },
    ];

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json(result),
    );
  }),
  rest.get(`${config.baseURL}/dashboard/no-check-count`, (req, res, ctx) => {
    const result: NoCheckCountGetResponseType = [
      {
        groupName: 'test1',
        noCheckedUntilNow: 1,
        checkedBeforeOneYear: 4,
      },
      {
        groupName: 'test2',
        noCheckedUntilNow: 3,
        checkedBeforeOneYear: 5,
      },
      {
        groupName: 'test3',
        noCheckedUntilNow: 2,
        checkedBeforeOneYear: 1,
      },
    ];

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json(result),
    );
  }),
  rest.get(`${config.baseURL}/dashboard/check-count-history`, (req, res, ctx) => {
    const fromYearMonth = req.url.searchParams.get('fromYearMonth');
    const toYearMonth = req.url.searchParams.get('toYearMonth');

    const dateList = dateRangeForStringList(
      new Date(`${fromYearMonth?.slice(0, 4)}/${fromYearMonth?.slice(4, 6)}/01`),
      new Date(`${toYearMonth?.slice(0, 4)}/${toYearMonth?.slice(4, 6)}/01`),
      'month',
      dateFormat.yyyy_slash_mm,
    );
    const result: CheckCountHistoryGetResponseType = {
      labels: dateList,
      datasets: Array.from(
        Array(
          faker.datatype.number({
            min: 0,
            max: 70,
          }),
        ).keys(),
      ).map((i) => {
        return {
          label: faker.word.noun(),
          data: dateList.map((i) =>
            faker.datatype.number({
              min: 0,
              max: 100,
            }),
          ),
        };
      }),
    };

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json(result),
    );
  }),
  rest.get(`${config.baseURL}/dashboard/reduced-time-minutes`, (req, res, ctx) => {
    const result: ReducedTimeMinutesGetResponseType = {
      high: faker.datatype.number({
        min: 0,
        max: 10000,
      }),
      middle: faker.datatype.number({
        min: 0,
        max: 10000,
      }),
      low: faker.datatype.number({
        min: 0,
        max: 10000,
      }),
    };

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json(result),
    );
  }),
  rest.get(`${config.baseURL}/dashboard/reduced-time-history`, (req, res, ctx) => {
    const fromYearMonth = req.url.searchParams.get('fromYearMonth');
    const toYearMonth = req.url.searchParams.get('toYearMonth');

    const dateList = dateRangeForStringList(
      new Date(`${fromYearMonth?.slice(0, 4)}/${fromYearMonth?.slice(4, 6)}/01`),
      new Date(`${toYearMonth?.slice(0, 4)}/${toYearMonth?.slice(4, 6)}/01`),
      'month',
      dateFormat.yyyy_slash_mm,
    );
    const result: CheckCountHistoryGetResponseType = {
      labels: dateList,
      datasets: Array.from(
        Array(
          faker.datatype.number({
            min: 0,
            max: 10,
          }),
        ).keys(),
      ).map((i) => {
        return {
          label: faker.word.noun(),
          data: dateList.map((i) =>
            faker.datatype.number({
              min: 0,
              max: 50,
            }),
          ),
        };
      }),
    };

    return res(
      ctx.delay(delayTime),
      // ctx.delay(0),
      ctx.status(200),
      ctx.json(result),
    );
  }),
];
