import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { TableResultType } from '@/shared/types/api.type';
import {
  FileManagementGetResponseType,
  FileManagementFileDownloadPostResponseType,
} from '@/shared/types/api/api.fileManagement';

export const getFiles = [
  rest.get<TableResultType<FileManagementGetResponseType[]>>(
    `${config.baseURL}/group/:groupId/file-management`,
    (req, res, ctx) => {
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const result = Array.from({ length: 102 }).map((i, index) => {
        return {
          fileManagementId: index + 1,
          fileName: `${index + 1} :: hogehoge - ${faker.word.noun()}`,
          fileExtension: 'csv',
          acceptedDateTime: faker.date.recent(),
          size: faker.datatype.number(),
          isDownloaded: faker.datatype.boolean(),
        };
      });

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: result.slice(offset, offset + limit),
        }),
      );
    },
  ),
];

export const downloadFiles = [
  rest.post<FileManagementFileDownloadPostResponseType[]>(
    `${config.baseURL}/group/:groupId/file-management/download`,
    async (req, res, ctx) => {
      const body = (await req.json()) as { fileManagementIds: number[] };
      const result = Array.from({
        length: body.fileManagementIds.length,
      }).map((i, index) => {
        return {
          fileName: `image-${faker.word.noun()}.png`,
          // TODO
          url: 'https://sample-images-sasaki.s3.ap-northeast-1.amazonaws.com/logo-00.png?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjENz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDmFwLW5vcnRoZWFzdC0xIkcwRQIhAJ31bHIdDZBbBmBRuln%2F6%2FwrwJn3ejwTcTdNKkadpAccAiBTCNUWh6RYhYf1euSry5R14xnIiLkZT0d%2BsyASHxgjJirRAwjl%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDI0MzU0NTAyNDc2NyIMsfHCnPwKdOb0ZhYUKqUDjClQ7G5jFl%2BJR1CQ3mxN696KuDYAu0suJY0in9vImm%2FEziAHXLrnUm3eWxJHcjbUWbSvHEN6MA5%2BXm%2BIc9OAkRUELJ0sq%2Bw9nf5g9xJaPi01vICJZWUdXvWCj6Rwe3ixrQscsCYO30b9pvP0WnbsKT81f60YrkJIM6qhqgeYbo9S2H7IdjN0y6%2Fv31YYS8jGlV13jGTQiFITaST4eTovyse0IdtbphpKIfi9xyr6SACuD%2FsoMU3dNhchR3KguV%2BWl9o1NVqplVl6TytZV2aU59V9Ze3ijJErV0EwLdxDAM9ZFuc3Yq3wHJ0lvTBbi%2FYAHfFzlc3TpfUXx6%2FIEVCeZGO0XOp7pTUm6pjNTkQztcMb9zJxuUIHw4hidfwIOBySarEB4wAf0HRCC2WrMduq3speA50cMc2gtw7t4f6LgfiRw3WWHBXVKSjdPHiVEZTTVFLN%2FKjC%2FYFR7taMLb%2BklhoKKLcvRXhcIMYDecU5H0tIPl8mqmMehCQmP%2BKV3HB27Qq0p1GBbTtJEuCe5LgTvetn8wS2mauzESrPA3AdknCmRwtAnTCV%2FoCcBjqUAufVuOoehMK9zyUZcGNl2bqN8xLjir%2Bv4tEcZSUkpCCbEZFNtdoGJW0zxyFOb5eDvd%2BYGxqxK%2BrpxY18KyL9%2BrUSv%2BvWbS1mPw%2BfubBWGMJAEG%2BmsUKIdqb%2BH2m95%2BxgHnHxeYA59p9Y7RoM%2FuRv0M4ZovfM2Vog%2B4mldzryYOwqTWZQAvSC44%2Fen6g15ZvPlOoeBo72R%2FmmKLzAlditAJyDTKvT1lTh996plmmUxDxIzPIQ4WweE9BB6UTzaA4c5bHfEigDxWxk3OJENyXgBSEQlUeASYtYf8QUEtfqjm9zGvK%2B%2BaVHhKXXD4Sb38PztTH8f5%2B2bgw524I%2FpfumBt0upsXmSS%2ByRPsjf3ZRmiIUkAg1hA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221125T040655Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=ASIATRNDKFD75REIF5OR%2F20221125%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Signature=994ed12c22e7c9f808f674d6dbe7347cefb5b3e9fdebb086c09f7070afc54a6b',
          fileExtension: 'png',
        };
      });

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset: 0,
          limit: 0,
          rows: result,
        }),
      );
    },
  ),
];
