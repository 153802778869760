import { axiosInstance } from '@/shared/api/axios';
import { ErrorCode } from '@/shared/constants/error';
import { TableResultType } from '@/shared/types/api.type';
import {
  FolderDeleteResponseType,
  FolderListGetRequestType,
  FolderListGetResponseType,
  FolderSettingsGetRequestType,
  FolderSettingsGetResponseType,
  FolderSettingsPutRequestType,
  FolderSettingsPutResponseType,
} from '@/shared/types/api/api.folderManagement';
import { replaceAllSpaceToHalfSpace } from '@/shared/utils/space';

export const getFolderList = async ({
  groupId,
  pageSize,
  pageIndex,
  sortTarget,
  sortOrder,
  folderName,
}: FolderListGetRequestType): Promise<TableResultType<FolderListGetResponseType[]>> => {
  const { data } = await axiosInstance.post<TableResultType<FolderListGetResponseType[]>>(
    `/group/${groupId}/folder/search`,
    {
      folderNames: folderName
        ? replaceAllSpaceToHalfSpace(folderName)
            .split(' ')
            .filter((d) => d !== '')
        : [],
    },
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
        sortTarget,
        sortOrder,
      },
    },
  );
  return data;
};

export const createFolderList = async (
  groupId: number,
  folderName: string,
): Promise<{ folderId: number }> => {
  const { data } = await axiosInstance.post(`/group/${groupId}/folder`, {
    name: folderName,
  });

  return data;
};

export const updateFolderList = async (
  groupId: number,
  folderId: number,
  folderName: string,
): Promise<{ folderId: number }> => {
  const { data } = await axiosInstance.put(`/group/${groupId}/folder/${folderId}`, {
    name: folderName,
  });
  return data;
};

export const deleteFolderList = async (
  groupId: number,
  folderIds: number[],
): Promise<FolderDeleteResponseType> => {
  const { data } = await axiosInstance.post<FolderDeleteResponseType>(
    `/group/${groupId}/folder/delete`,
    {
      folderIdList: folderIds,
    },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_RESOURCE_NOT_FOUND,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: 'フォルダ削除',
            message: '削除済みのフォルダです。',
          },
        },
      ],
    },
  );
  return data;
};

export const getFolderSettings = async ({
  groupId,
  folderId,
  pageSize,
  pageIndex,
  customerName,
  sortTarget,
  sortOrder,
}: FolderSettingsGetRequestType): Promise<TableResultType<FolderSettingsGetResponseType[]>> => {
  const { data } = await axiosInstance.post<TableResultType<FolderSettingsGetResponseType[]>>(
    `/group/${groupId}/folder-setting`,
    {
      customerNames: customerName
        ? replaceAllSpaceToHalfSpace(customerName)
            .split(' ')
            .filter((d) => d !== '')
        : [],
    },
    {
      params: {
        limit: pageSize,
        offset: (pageIndex - 1) * pageSize,
        sortTarget,
        sortOrder,
        folderId,
      },
    },
  );
  return data;
};

export const updateFolderSetting = async ({
  groupId,
  folderId,
  customerIdList,
}: FolderSettingsPutRequestType): Promise<FolderSettingsPutResponseType> => {
  const { data } = await axiosInstance.put(
    `/group/${groupId}/folder-setting`,
    {
      folderId,
      customerIdList,
    },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_RESOURCE_NOT_FOUND,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: 'フォルダ設定',
            message: '削除済みのフォルダです。',
          },
        },
      ],
    },
  );
  return data;
};
