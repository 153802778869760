import { atom } from 'recoil';

import { BlacklistRestrictionDialogType } from '@/shared/types/common.type';

export const blacklistRestrictionDialogState = atom<BlacklistRestrictionDialogType>({
  key: 'blacklistRestrictionDialog',
  default: {
    open: false,
  },
});
