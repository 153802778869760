import { DefaultValue, selector } from 'recoil';

import { messageDialogState } from '@/recoil/atoms/messageDialogState';
import { MessageDialogType } from '@/shared/types/common.type';

export const messageDialogSelector = selector<MessageDialogType>({
  key: 'messageDialogSelector',
  get: ({ get }) => get(messageDialogState),
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue || !newValue.open) {
      reset(messageDialogState);
    }
    set(messageDialogState, newValue);
  },
});
