import axios from 'axios';

import { constants } from '@/shared/constants';
import { redirectLoginForm } from '@/shared/utils/redirectLogin';

const axiosInstanceWithLoginCheck = axios.create({
  maxRedirects: 0,
  withCredentials: true,
  maxBodyLength: Infinity,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});
// platform側のセッションチェック
export const platformLoginCheck = async () => {
  // NOTE:local実行の場合チェック実施しない
  if (import.meta.env.VITE_LOCAL_ENABLE === '1') return;
  const userId = Number(localStorage.getItem(constants.localstorage().userId) || 0);
  try {
    const { data } = await axiosInstanceWithLoginCheck.get(
      import.meta.env.VITE_PORTAL_LOGIN_CHECK_URL,
    );
    if (data?.result === 'OK' && data?.isFutureDate === true) {
      window.location.href = import.meta.env.VITE_PORTAL_TOP_URL;
    }
    if (data?.result !== 'OK' || data?.portalUserId !== userId) {
      redirectLoginForm();
    }
  } catch (error: any) {
    redirectLoginForm();
  }
};
