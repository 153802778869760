import { axiosInstance } from '@/shared/api/axios';
import { ErrorCode } from '@/shared/constants/error';
import { complianceToReferenceCheckLabel } from '@/shared/dictionary';
import {
  CheckAllExecutionPostRequestType,
  CheckAllExecutionPostResponseType,
  CheckExecutionErrorCheckGetResponseType,
  CheckExecutionPostRequestType,
  CheckExecutionPostResponseType,
  CheckReExecutionPostRequestType,
  CheckReExecutionPostResponseType,
} from '@/shared/types/api/api.checkExecution';

export const execution = async ({
  executionTypes,
  customerIds,
}: CheckExecutionPostRequestType): Promise<CheckExecutionPostResponseType> => {
  const { data } = await axiosInstance.post(
    `/execution`,
    {
      executionTypes,
      customerIds,
    },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_CREDIT_CHECK_IN_PROGRESS,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: '与信調査',
            message: '現在、与信情報調査中の取引先があるため実行できませんでした。',
          },
        },
      ],
    },
  );

  return data;
};

export const allExecution = async ({
  executionTypes,
  folderId,
}: CheckAllExecutionPostRequestType): Promise<CheckAllExecutionPostResponseType> => {
  const { data } = await axiosInstance.post(
    `/execution/all`,
    {
      executionTypes,
      folderId,
    },
    {
      errorMessages: [
        {
          code: ErrorCode.ERROR_RESOURCE_NOT_FOUND,
          type: 'dialog',
          messageDialog: {
            open: true,
            title: '全件チェック',
            message:
              complianceToReferenceCheckLabel('指定したフォルダに紐づく取引先はありません。'),
          },
        },
      ],
    },
  );

  return data;
};

export const reExecution = async ({
  concurrentExecutionIds,
  withNewspaper,
}: CheckReExecutionPostRequestType): Promise<CheckReExecutionPostResponseType> => {
  const { data } = await axiosInstance.post(`/re-execution`, {
    concurrentExecutionIds,
    withNewspaper,
  });

  return data;
};
export const executionErrorCheck = async (
  groupId: number,
): Promise<CheckExecutionErrorCheckGetResponseType> => {
  const { data } = await axiosInstance.post(`/execution/groups/${groupId}/check`);
  return data;
};
