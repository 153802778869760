import React, { useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { MessageBodyStyle, MessageLabelStyle } from '@/components/MessageDialog/css';
import { messageDialogSelector } from '@/recoil/selectors/messageDialogSelector';
import Button from '@/shared/components/Button';
import ModalTemplate from '@/shared/components/DialogTemplate';

const MessageDialog: React.FC = () => {
  const { title, message, handleClose, contents, open, width } =
    useRecoilValue(messageDialogSelector);
  const setDialogState = useSetRecoilState(messageDialogSelector);

  const onClose = useCallback(() => {
    setDialogState({ open: false });
    handleClose && handleClose();
  }, [handleClose, setDialogState]);
  return (
    <ModalTemplate
      open={open}
      handleClose={onClose}
      width={width ?? 450}
      title={title ?? ''}
      fontSize="2rem"
      contents={
        contents ?? (
          <MessageBodyStyle>
            <MessageLabelStyle>{message}</MessageLabelStyle>
          </MessageBodyStyle>
        )
      }
      footer={<Button text="OK" height={40} onClick={onClose} width={150} />}
    />
  );
};

export default MessageDialog;
