export type CheckablePeriodUnitType = 'WITHIN_DAY' | 'WITHIN_WEEK' | 'WITHIN_MONTH' | 'WITHIN_YEAR';
export type CheckDecisionChoiceUnitType = 'NONE' | 'APPROVE' | 'DISAPPROVE' | 'PENDING';
export type SearchCreditType = 'UNSELECTED' | 'DOWNLOADED' | 'MANUAL_UPLOADED';
export type RiskLevelScoreType = 'HIGH' | 'MIDDLE' | 'LOW' | 'NONE';
export type SearchDateType = 'ALL' | 'UNIT' | 'DATE';
export type ReflectionType = 'ALL' | 'FOLDER' | 'CHECKED_AT';
export type DateUnitType = 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
export type ExecuteType =
  | 'GOOGLE_SEARCH'
  | 'NEWSPAPER_SEARCH'
  | 'CREDIT_MANAGEMENT_SEARCH'
  | 'CREDIT_MANAGEMENT_DOWNLOAD';

export type ExecuteStatusType =
  | 'PENDING'
  | 'IS_EXECUTING'
  | 'IS_FINISHED'
  | 'NON_DISPLAY'
  | 'IS_ANALYZING'
  | 'ERROR'
  | 'SUSPENDED'
  | 'SQS_MESSAGING_ERROR';
export type FileStatusType =
  | 'CREATED'
  | 'CREATING'
  | 'FAILED'
  | 'SAVED'
  | 'DELETED'
  | 'BPO_CREATING'
  | 'BPO_CREATED'
  | 'BPO_FAILED'
  | 'BPO_SAVED';

export type ApiAuthInformationKindType = 'G_SEARCH' | ApiSettingUnitType;
export type ApiSettingUnitType = 'SALESFORCE' | 'GOOGLE_SPREAD_SHEET' | 'KINTONE';

export type DuplicateType = 'INSERT' | 'UPDATE' | 'NOT';

export const code = {
  date_unit: {
    day: 'DAY',
    week: 'WEEK',
    month: 'MONTH',
    year: 'YEAR',
  },
  checkable_period_unit: {
    day: 'WITHIN_DAY',
    week: 'WITHIN_WEEK',
    month: 'WITHIN_MONTH',
    year: 'WITHIN_YEAR',
  },
  check_decision_choice_unit: {
    none: 'NONE',
    approve: 'APPROVE',
    disapprove: 'DISAPPROVE',
    pending: 'PENDING',
  },
  risk_level_score_unit: {
    high: 'HIGH',
    middle: 'MIDDLE',
    low: 'LOW',
    none: 'NONE',
  },
  execute_status_unit: {
    pending: 'PENDING',
    is_executing: 'IS_EXECUTING',
    is_finished: 'IS_FINISHED',
    non_display: 'NON_DISPLAY',
    is_analyzing: 'IS_ANALYZING',
    error: 'ERROR',
    suspended: 'SUSPENDED',
    sqs_messaging_error: 'SQS_MESSAGING_ERROR',
  },
  search_date_type_unit: {
    all: 'ALL',
    unit: 'UNIT',
    date: 'DATE',
  },
  reflection_type_unit: {
    all: 'ALL',
    folder: 'FOLDER',
    checked_at: 'CHECKED_AT',
  },
  file_status_type_unit: {
    created: 'CREATED',
    creating: 'CREATING',
    failed: 'FAILED',
    saved: 'SAVED',
    deleted: 'DELETED',
    bpo_creating: 'BPO_CREATING',
    bpo_created: 'BPO_CREATED',
    bpo_failed: 'BPO_FAILED',
    bpo_saved: 'BPO_SAVED',
  },
  api_setting_unit: {
    salesforce: 'SALESFORCE',
    google_spread_sheet: 'GOOGLE_SPREAD_SHEET',
    kintone: 'KINTONE',
  },
  duplicate_unit: {
    insert: 'INSERT',
    update: 'UPDATE',
    not: 'NOT',
  },
  caution_classification_unit: {
    one: 1,
    two: 2,
    three: 3,
    four: 4,
  },
};

export const codeConverter = {
  date_unit: {
    DAY: '日後',
    WEEK: '週後',
    MONTH: 'か月後',
    YEAR: '年後',
  },
  checkable_period_unit: {
    WITHIN_DAY: '日以内',
    WITHIN_WEEK: '週間以内',
    WITHIN_MONTH: 'か月以内',
    WITHIN_YEAR: '年以内',
  },
  checkable_period_unit_short_name: {
    WITHIN_DAY: '日',
    WITHIN_WEEK: '週',
    WITHIN_MONTH: '月',
    WITHIN_YEAR: '年',
  },
  check_decision_choice_unit: {
    NONE: 'ー',
    APPROVE: '承認',
    DISAPPROVE: '否認',
    PENDING: '保留',
  },
  risk_level_score_unit: {
    HIGH: '高',
    MIDDLE: '中',
    LOW: '低',
    NONE: '判定不可',
  },
  llm_risk_level_score_unit: {
    HIGH: 'リスクLv 高',
    MIDDLE: 'リスクLv 中',
    LOW: 'リスクLv 低',
    NONE: '対象外',
    NOT_LLM: 'リスクLv',
  },
  execute_status_unit: {
    PENDING: '実行待ち',
    IS_EXECUTING: '実行中',
    IS_FINISHED: '実行終了（正常)',
    NON_DISPLAY: '非表示',
    IS_ANALYZING: '分析中（GoogleSearch）',
    ERROR: 'エラー終了',
    SUSPENDED: '実行中断',
    SQS_MESSAGING_ERROR: 'SQSメッセージングエラー',
  },
  search_date_type_unit: {
    ALL: '全期間',
    UNIT: '単位指定',
    DATE: '日付指定',
  },
  file_status_type_unit: {
    CREATED: 'ファイル作成完了',
    CREATING: 'ファイル作成中',
    FAILED: 'ファイル作成失敗',
    SAVED: '保存済',
    DELETED: '削除済',
    BPO_CREATING: '',
    BPO_CREATED: '',
    BPO_FAILED: '',
    BPO_SAVED: '',
  },
};

export const authCode = {
  authority: {
    OWNER: 1, // オーナー権限
    ALL: 2, // 全権限
    EXECUTE: 3, // チェック実行
    READONLY: 4, // 観覧
  },
  contractType: {
    NET: 1, // ネット
    NET_CREDIT: 2, // ネット、与信
    NET_PAPER_CREDIT: 3, // ネット、新聞検索、与信
    NET_PAPER_CREDIT_NOT_G_SEARCH_KEY: 4, // ネット、新聞検索、与信(g search_keyなし)
  },
  contractUnitType: {
    MONTHLY: 1, // 月間契約プラン：件数（自由）あり
    YEARLY: 2, // 年間契約プラン：件数（自由）あり
    PAY_PER_USE: 3, // 完全従量制：無制限
  },
  paymentType: {
    PAID: 1, // 有償
    TRIAL: 2, // 無償トライアル
    FREEMIUM: 3, // 無償（無償期間超）
  },
  serviceType: {
    COMPLIANCE: 0, // コンプライアンス
    REFERENCE_CHECK: 1, // リファレンスチェック
  },
};

export const serviceName: { [key: string]: number } = {
  compliance: 0,
  reference: 1,
};
