import { axiosInstance } from '@/shared/api/axios';
import { TableResultType } from '@/shared/types/api.type';
import {
  CreditManagementDownloadGetRequestType,
  CreditManagementDownloadGetResponseType,
  CreditManagementDownloadPostRequestType,
  CreditManagementDownloadPostResponseType,
  CreditManagementDownloadsExecuteStatusGetRequestType,
  CreditManagementDownloadsExecuteStatusGetResponseType,
  CreditManagementExecutionStatusGetRequestType,
  CreditManagementExecutionStatusGetResponseType,
  CreditManagementFileDownloadGetRequestType,
  CreditManagementFileDownloadGetResponseType,
} from '@/shared/types/api/api.creditManagement';

export const getCreditManagementDownload = async ({
  groupId,
  customerId,
  pageIndex,
  pageSize,
}: CreditManagementDownloadGetRequestType): Promise<
  TableResultType<CreditManagementDownloadGetResponseType[]>
> => {
  const { data } = await axiosInstance.get<
    TableResultType<CreditManagementDownloadGetResponseType[]>
  >(`/group/${groupId}/customer/${customerId}/credit-management-downloads`, {
    params: {
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize,
    },
  });
  return data;
};

export const getCreditManagementExecuteStatus = async ({
  groupId,
  customerId,
}: CreditManagementExecutionStatusGetRequestType) => {
  const { data } = await axiosInstance.get<CreditManagementExecutionStatusGetResponseType>(
    `/group/${groupId}/customer/${customerId}/credit-management-execute-result/status`,
  );
  return data;
};

export const getCreditManagementDownloadsExecuteStatus = async ({
  groupId,
  customerId,
}: CreditManagementDownloadsExecuteStatusGetRequestType) => {
  const { data } = await axiosInstance.get<CreditManagementDownloadsExecuteStatusGetResponseType>(
    `/group/${groupId}/customer/${customerId}/credit-management-downloads/execute/status`,
  );
  return data;
};

export const uploadCreditManagementsFile = async ({
  groupId,
  customerId,
  fileSize,
  fileName,
  fileExtension,
}: CreditManagementDownloadPostRequestType) => {
  const { data } = await axiosInstance.post<CreditManagementDownloadPostResponseType>(
    `/group/${groupId}/customer/${customerId}/credit-management-downloads/file-upload`,
    {
      fileSize,
      fileName,
      fileExtension,
    },
  );
  return data;
};

export const downloadCreditManagementFiles = async ({
  groupId,
  creditManagementDownloadId,
}: CreditManagementFileDownloadGetRequestType) => {
  const { data } = await axiosInstance.get<CreditManagementFileDownloadGetResponseType>(
    `/group/${groupId}/credit-management-downloads/${creditManagementDownloadId}/file-download`,
  );
  return data;
};
