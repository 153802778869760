import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import {
  NewspaperConditionGetResponseType,
  NewspaperConditionPutResponseType,
} from '@/shared/types/api/api.newspaperCondition';
export const newspaperConditionSettings = [
  rest.get<NewspaperConditionGetResponseType>(
    `${config.baseURL}/group/:groupId/newspaper-condition`,
    (req, res, ctx) => {
      const result: NewspaperConditionGetResponseType = {
        isSearchActivated: faker.datatype.boolean(),
        //isSearchActivated: false,
        isPeriodActivated: faker.datatype.boolean(),
        searchStartDate: String(faker.date.recent()),
        searchEndDate: String(faker.date.recent()),
        operatorAnd: faker.word.noun(),
        operatorNot: faker.word.noun(),
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
  rest.put<NewspaperConditionPutResponseType>(
    `${config.baseURL}/group/:group_id/newspaper-condition`,
    async (req, res, ctx) => {
      const body = (await req.json()) as NewspaperConditionPutResponseType;
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(body),
      );
    },
  ),
];
