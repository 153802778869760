import { axiosInstance } from '@/shared/api/axios';
import {
  FunctionUsagePostRequestType,
  FunctionUsagePostResponseType,
} from '@/shared/types/api/api.functionUsage';

export const postFunctionUsage = async ({
  actionType,
  page,
  functionType,
}: FunctionUsagePostRequestType): Promise<FunctionUsagePostResponseType> => {
  const { data } = await axiosInstance.post<FunctionUsagePostResponseType>('/function-usage', {
    actionType,
    page,
    functionType,
  });
  return data;
};
