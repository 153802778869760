import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooksOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import { checkServiceType } from '../utils/serviceType';

import lazyImport from '@/components/lazyImport';
import { authCode } from '@/shared/constants/code';
import { complianceToReferenceCheckLabel } from '@/shared/dictionary';
import { RoutesType } from '@/shared/types/route.type';

const ApiSetting = lazyImport(() => import('@/screens/ApiSetting'));
const ApiSettingDetail = lazyImport(() => import('@/screens/ApiSettingDetail'));
const Dashboard = lazyImport(() => import('@/screens/Dashboard'));
const Customers = lazyImport(() => import('@/screens/Customers'));
const CustomersInfo = lazyImport(() => import('@/screens/CustomerInformation'));
const CreditManagement = lazyImport(() => import('@/screens/CreditManagement'));
const CheckConditionSetting = lazyImport(() => import('@/screens/CheckConditionSetting'));
const CheckResults = lazyImport(() => import('@/screens/CheckResults'));
const CheckResultsDetail = lazyImport(() => import('@/screens/CheckResultDetail'));
const Files = lazyImport(() => import('@/screens/FileManagement'));
const Folders = lazyImport(() => import('@/screens/Folders'));
const NewspaperPrice = lazyImport(() => import('@/screens/NewspaperPrice'));
const CautionList = lazyImport(() => import('@/screens/CautionList'));
const Blacklist = lazyImport(() => import('@/screens/Blacklist'));

const CustomerSubHeader = lazyImport(() => import('@/components/CustomerSubHeader'));
const NewsPaperPriceSubHeader = lazyImport(() => import('@/components/NewsPaperPriceSubHeader'));

export const paths = {
  customers: '/customers',
  customersInfo: '/groups/:groupId/customers/:customerId',
  creditManagement: '/groups/:groupId/customers/:customerId/credit-management',
  checkConditionSetting: '/condition-settings',
  checkResults: '/check-results',
  checkResultDetail:
    '/groups/:groupId/customers/:customerId/check-results/:checkExecutionId/detail',
  fileManagement: '/fileManagement',
  folders: '/folders',
  dashboard: '/dashboard',
  apiSettings: '/api-setting',
  addApiSetting: '/group/:groupId/api-setting/add',
  newspaperPrice: '/news-paper-price',
  cautionList: '/cautionList',
  blacklist: '/blacklist',
};

const routes: RoutesType[] = [
  {
    name: complianceToReferenceCheckLabel('取引先一覧／登録'),
    subTitle: CustomerSubHeader,
    path: paths.customers,
    component: Customers,
    icon: LibraryBooksIcon,
    instructionVideo: {
      title: complianceToReferenceCheckLabel('取引先登録・チェック実行の流れ'),
      videoUrl: import.meta.env.VITE_CUSTOMER_MANUAL_VIDEO_URL,
    },
    hasSearchBar: true,
    searchBarOption: {
      width: '392px',
    },
    hasFooter: false,
    showMenu: true,
  },
  {
    name: complianceToReferenceCheckLabel('取引先情報'),
    path: paths.customersInfo,
    component: CustomersInfo,
    icon: LibraryBooksIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: false,
  },
  {
    name: '与信情報取得',
    path: paths.creditManagement,
    component: CreditManagement,
    icon: LocationCityOutlinedIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: false,
    deIncludeAuthority: [authCode.authority.READONLY],
  },
  {
    name: 'チェック条件設定',
    path: paths.checkConditionSetting,
    component: CheckConditionSetting,
    icon: SettingsOutlinedIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: true,
    deIncludeAuthority: [authCode.authority.EXECUTE, authCode.authority.READONLY],
  },
  {
    name: 'チェック結果一覧',
    path: paths.checkResults,
    component: CheckResults,
    icon: AssignmentTurnedInOutlinedIcon,
    instructionVideo: {
      title: complianceToReferenceCheckLabel('取引判断の流れ'),
      videoUrl: import.meta.env.VITE_CHECK_RESULT_MANUAL_VIDEO_URL,
    },
    hasSearchBar: true,
    searchBarOption: {
      width: '380px',
    },
    hasFooter: false,
    showMenu: true,
  },
  {
    name: 'チェック結果詳細',
    path: paths.checkResultDetail,
    component: CheckResultsDetail,
    icon: AssignmentTurnedInOutlinedIcon,
    instructionVideo: {
      title: 'チェック結果詳細ダウンロードの流れ',
      videoUrl: import.meta.env.VITE_RESULT_DETAIL_MANUAL_VIDEO_URL,
    },
    hasSearchBar: false,
    hasFooter: false,
    showMenu: false,
  },
  {
    name: 'ファイル保存',
    path: paths.fileManagement,
    component: Files,
    icon: FileDownloadOutlinedIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: true,
    deIncludeAuthority: [authCode.authority.READONLY],
  },
  {
    name: 'フォルダ管理',
    path: paths.folders,
    component: Folders,
    icon: FolderOutlinedIcon,
    hasSearchBar: true,
    searchBarOption: {
      width: '392px',
    },
    hasFooter: false,
    showMenu: true,
    deIncludeAuthority: [authCode.authority.EXECUTE, authCode.authority.READONLY],
  },
  {
    name: 'ダッシュボード',
    path: paths.dashboard,
    component: Dashboard,
    icon: BarChartIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: true,
  },
  {
    name: 'API設定',
    path: paths.apiSettings,
    component: ApiSetting,
    icon: SettingsOutlinedIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: true,
    deIncludeAuthority: [authCode.authority.EXECUTE, authCode.authority.READONLY],
    display: ({ contract }) => {
      return (
        ![authCode.paymentType.FREEMIUM, authCode.paymentType.TRIAL].includes(
          contract?.paymentType || 0,
        ) || !checkServiceType()
      );
    },
  },
  {
    name: 'API設定',
    path: paths.addApiSetting,
    component: ApiSettingDetail,
    icon: SettingsOutlinedIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: false,
    deIncludeAuthority: [authCode.authority.EXECUTE, authCode.authority.READONLY],
  },
  {
    name: '注意先リスト登録',
    path: paths.cautionList,
    component: CautionList,
    icon: SummarizeOutlinedIcon,
    hasSearchBar: true,
    searchBarOption: {
      width: '392px',
    },
    hasFooter: false,
    showMenu: true,
    display: ({ useCautionListFlg }) => {
      return useCautionListFlg === undefined ? false : useCautionListFlg;
    },
  },
  {
    name: 'ブラックリスト登録',
    path: paths.blacklist,
    component: Blacklist,
    icon: SummarizeOutlinedIcon,
    hasSearchBar: true,
    searchBarOption: {
      width: '392px',
    },
    hasFooter: false,
    showMenu: true,
    display: ({ useCautionListFlg, authority }) => {
      return (
        !(useCautionListFlg === undefined ? false : useCautionListFlg) &&
        authority === authCode.authority.OWNER
      );
    },
  },
  {
    name: '料金一覧（新聞・雑誌記事横断検索）',
    path: paths.newspaperPrice,
    component: NewspaperPrice,
    subTitle: NewsPaperPriceSubHeader,
    icon: AssignmentTurnedInOutlinedIcon,
    hasSearchBar: false,
    hasFooter: false,
    showMenu: false,
  },
];

export default routes;
