import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { AuthPostResponseType } from '@/shared/types/api/api.auth';

export const auth = [
  rest.post<AuthPostResponseType>(`${config.baseURL}/authorize/code`, async (req, res, ctx) => {
    return res(
      ctx.delay(delayTime),
      ctx.status(200),
      ctx.json({
        access_token:
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEyMzQ1Njc4OTAsInNlcnZpY2VUeXBlIjoxLCJhdXRob3JpdHkiOjEsImNvbnRyYWN0Ijp7ImF0dGFjaEZpbGVGb3JDb21tZW50Ijp0cnVlLCJjb250cmFjdFN0YXJ0RGF0ZSI6MTUxNjIzOTAyMiwicGF5bWVudFR5cGUiOjEsInR5cGUiOjEsInVuaXRMaW1pdCI6MTAwLCJ1bml0VHlwZSI6MX0sImlhdCI6MTUxNjIzOTAyMn0.xmoMSXJE-BrtosUkMFYX2WuUOC_zYIvhR542h6u1CtM',
        refresh_token: 'refresh_token',
        expires_in: new Date().valueOf(),
      }),
    );
  }),
];
