import { axiosInstanceWithFileUpload } from '@/shared/api/axios';
import { fileUploadType } from '@/shared/types/api/api.file';

export const fileUpload = async ({ uploadUrl, file }: fileUploadType) => {
  const { data } = await axiosInstanceWithFileUpload.put(uploadUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
  return data;
};
