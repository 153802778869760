import { rest } from 'msw';
import { config } from '@/shared/api/axios';
import { delayTime } from '@/shared/mocks/handler';
import { faker } from '@faker-js/faker';
import { TableResultType } from '@/shared/types/api.type';
import {
  CreditManagementDownloadGetResponseType,
  CreditManagementDownloadPostResponseType,
  CreditManagementExecutionStatusGetResponseType,
  CreditManagementFileDownloadGetResponseType,
} from '@/shared/types/api/api.creditManagement';

export const getCreditManagementDownload = [
  rest.get<TableResultType<CreditManagementDownloadGetResponseType[]>>(
    `${config.baseURL}/group/:group_id/customer/:customer_id/credit-management-downloads`,
    (req, res, ctx) => {
      const limit: number = Number(req.url.searchParams.get('limit'));
      const offset: number = Number(req.url.searchParams.get('offset'));
      const result = Array.from({ length: 10 }).map((i, index) => {
        return {
          creditManagementDownloadId: index + 1,
          downloadedAt: faker.date.recent(index),
          userLastNameKanji: faker.name.lastName(),
          userFirstNameKanji: faker.name.firstName(),
          userLastNameKana: faker.name.lastName(),
          userFirstNameKana: faker.name.firstName(),
          fileName: `${index + 1} :: CreditManagements - ${faker.word.noun()}`,
          fileExtension: 'pdf',
        };
      });
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json({
          total: result.length,
          offset,
          limit,
          rows: result.slice(offset, offset + limit),
        }),
      );
    },
  ),
];

export const getCreditManagementExecuteStatus = [
  rest.get<CreditManagementExecutionStatusGetResponseType>(
    `${config.baseURL}/group/:group_id/customer/:customer_id/credit-management-execute-result/status`,
    (req, res, ctx) => {
      const result: CreditManagementExecutionStatusGetResponseType = {
        executeStatus: 'IS_FINISHED', //'IS_FINISHED', //'ERROR', //'IS_FINISHED',
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const uploadCreditManagementsFile = [
  rest.post<CreditManagementDownloadPostResponseType>(
    `${config.baseURL}/group/:group_id/customer/:customer_id/credit-management-downloads/file-upload`,
    (req, res, ctx) => {
      const result: CreditManagementDownloadPostResponseType = {
        s3UploadUrl: '', // TODO
      };

      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];

export const downloadCreditManagementFiles = [
  rest.get<CreditManagementFileDownloadGetResponseType>(
    `${config.baseURL}/group/:group_id/credit-management-downloads/:creditManagementDownloadId/file-download`,
    (req, res, ctx) => {
      const result: CreditManagementFileDownloadGetResponseType = {
        s3DownloadUrl: '',
        fileExtension: 'csv',
        fileName: 'fileName',
      };
      return res(
        ctx.delay(delayTime),
        // ctx.delay(0),
        ctx.status(200),
        ctx.json(result),
      );
    },
  ),
];
